import React from 'react';
import AppCheckbox from '../../../core/components/inputs/AppCheckbox';
import {Grid} from '@material-ui/core';
import MultipleStopCard from './MultipleStopCard';
import AppButton from '../../../core/components/AppButton/AppMuiButton';
import {Save, Undo} from '@material-ui/icons';
import AppAlertStatic from '../../../core/components/AppAlertStatic';
import SecureField from "../permissions/SecureField";
import AppMuiDialog from "../../../core/components/AppDialog/AppMuiDialog";
import TgfButtonBasic from "../../../core/kendo/buttons/TgfButtonBasic";

const renderMultipleStopsForm = ({ values, originalValues, errors, isValid, isDirty, setFieldValue, resetForm, addArrayValue, removeArrayValue, setSupportingValue, supportingValues, submitForm }) => {

  const customerId = values.shipment ? values.shipment.customerId : -1;

  const handleResetClick = (e) =>
    resetForm();

  const handleChange = (index) => (key, value) => {
    setFieldValue(`multipleStops[${index}].${key}`, value);
  };

  const handleCheckboxChanged = (key) => (e) => {
    setFieldValue(key, e.target.checked);
  };

  const handleLocationChanged = (index) => (company) => {
    if (company) {
      setFieldValue(`multipleStops[${index}].selectedCompany`, company);
      setFieldValue(`multipleStops[${index}].companyId`, company.companyId);
    }
    else {
      setFieldValue(`multipleStops[${index}].selectedCompany`, null);
      setFieldValue(`multipleStops[${index}].companyId`, null);
    }
  };

  const handleStopTypeChanged = (index) => (stopType) => {
    setFieldValue(`multipleStops[${index}].selectedStopType`, stopType);
    switch (stopType.value) {
      case 'isPickup':
        setFieldValue(`multipleStops[${index}].isPickup`, true);
        setFieldValue(`multipleStops[${index}].isDelivery`, false);
        setFieldValue(`multipleStops[${index}].isUnloadReload`, false);
        return;
      case 'isDelivery':
        setFieldValue(`multipleStops[${index}].isPickup`, false);
        setFieldValue(`multipleStops[${index}].isDelivery`, true);
        setFieldValue(`multipleStops[${index}].isUnloadReload`, false);
        return;
      case 'isUnloadReload':
        setFieldValue(`multipleStops[${index}].isPickup`, false);
        setFieldValue(`multipleStops[${index}].isDelivery`, false);
        setFieldValue(`multipleStops[${index}].isUnloadReload`, true);
        return;
      default:
        return;
    }
  };

  const handleCompanySearch = (index, companyId) => (searchTerm) => {

    if (searchTerm.trim().length > 0) {
      const option = {
        filter: {
          companyName: {
            $like: `${searchTerm}%`
          },
          affiliateCompanyId: companyId
        },
        offset: 0,
        limit: 25,
        sort: [['companyName', 'asc']]
      };

      values.searchCompanies(option)
        .then(companies => {
          setFieldValue(`candidateCompanies`, companies);
        });
    }
    else {
      setFieldValue(`candidateCompanies`, []);
    }
  };

  const handleClearCard = (index) => () => {
    removeArrayValue('multipleStops', index);
    addArrayValue('multipleStops', values.createEmptyCompany());
  };

  const handleSaveClick = () => {
    // If !selectedCompany && selectedStopType, then show warning and stop save.
    for(let stop of values.multipleStops) {
      const extractedValsArr = [
        'selectedCompany',
        'selectedStopType',
        'arrivalDate',
        'arrivalTime',
        'isDelivery',
        'isPickup',
        'isUnloadReload',
        'note',
        'reference'
      ].map(key => stop[key]);
      const allNullOrFalse = extractedValsArr.every((v) => v === null || v === false);
      // IF no selectedCompany && all other values arent null or false, show error & prevent save.
      if(!stop.selectedCompany && !allNullOrFalse) {
        setSupportingValue('showWarningDialog', true);
        return;
      }
    }
    submitForm();
  }

  const warningMessageAction =
    [{
      title: 'Close',
      action: () => {
        setSupportingValue('showWarningDialog', false)
      }
    }];

  const handleAddAdditionalStop = (e) => {
      e.preventDefault();
      const newStop = values.createNewStop();
      addArrayValue('multipleStops', Object.assign({}, newStop,
          {
              selectedStopType: values.getCurrentSelectedStopType(newStop),
              selectedCompany: newStop.company ?
                  values.convertToSelectedCompany(newStop.company) :
                  null
          }));
  }


  const renderMultipleStop = (stop, index) =>
    <Grid item xs={12} key={index}>
      <MultipleStopCard
        stop={values.multipleStops[index]}
        companies={values.candidateCompanies}
        title={`Stop ${index + 1}`}
        stopTypes={values.stopTypes}
        isMultipleStop={values.isMultipleStop}
        onLocationChanged={handleLocationChanged(index)}
        onStopTypeChanged={handleStopTypeChanged(index)}
        onCompanySearch={handleCompanySearch(index, customerId)}
        onFieldChange={handleChange(index)}
        onClearCard={handleClearCard(index)}
        customerId={customerId}
        errors={errors}
        shipment={values.shipment}
      />
    </Grid>

  return (
    <React.Fragment>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item>
              <AppButton
                onClick={handleSaveClick}
                startIcon={<Save />}
                disabled={!(isValid && isDirty)}
              >
                Save
              </AppButton>
            </Grid>
            <Grid item>
              <AppButton
                startIcon={<Undo />}
                color={'warning'}
                disabled={!isDirty}
                onClick={handleResetClick}
              >
                Reset
              </AppButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <SecureField
            pageName={'multipleStops'}
            fieldName={'isMultiStop'}
            as={AppCheckbox}
            id={'isMultiStop'}
            type={'checkbox'}
            label={'Confirm this is a Multi-Stop Truckload'}
            checked={values.isMultipleStop}
            onChange={handleCheckboxChanged('isMultipleStop')}
          />
        </Grid>
        <Grid item xs={12}>
          <AppAlertStatic title={'Bill of Lading Consignee'}>
            <p>Consignee chosen on Details page will be the ultimate destination.</p>
            <p>Be sure that all pages of the rate confirmation are signed and on file to prevent service and financial issues.</p>
          P/U Time, P/U Ref, Del Date, Del Time and Del Ref entered on the Details page will only apply to the Shipper and Consignee chosen on that same screen. Select the additional stops below and enter each stop's own information.
        </AppAlertStatic>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            {
              values.multipleStops.map(renderMultipleStop)
            }
          </Grid>
      </Grid>
          <Grid item style={{padding: '20px 0'}}>
              <TgfButtonBasic
                  onClick={handleAddAdditionalStop}
                  disabled={Boolean(values.multipleStops.length >= 20 || ![1, 6, 17, 19].includes(values.shipment.statusId))}
              >
                  ADD ADDITIONAL STOP
              </TgfButtonBasic>
          </Grid>
      </Grid>
      {supportingValues.showWarningDialog && <AppMuiDialog
        title={'Warning'}
        AppDialogContent={warningDialogMessage}
        open={true}
        actionButtons={warningMessageAction}
        width={'sm'}
      ></AppMuiDialog> }
    </React.Fragment>
  );
};

const warningDialogMessage = () => (
  <div style={{padding: "0 40px", color: 'red', fontWeight: 600}}>
    <p>
      You cannot save at this time because a stop's Location field is not completed. If you are needing to delete/remove a stop, you must click the red trashcan icon in the upper-right corner of the involved stop.
    </p>
  </div>
);

export default renderMultipleStopsForm;