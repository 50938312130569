import React from "react";
import { Button, Tooltip } from "@material-ui/core";
import FindByPath from "../utils/FindByPath"
import {TgfFormContext} from "../TgfFormContext";

const TgfButtonFormField = ({
                                tgfFormContext = new TgfFormContext({}),
                                variant = "contained",
                                color = "primary",
                                type = null,
                                mouseOverTooltipContent = null,
                                disabledFunction = (tgfFormContext) => false,
                                contentFunction = (tgfFormContext) => {},
                                isVisibleFunction = (tgfFormContext) => true,
                                onClickHandler = (tgfFormContext) => {},
                            }) => {

    const internalOnClick = () => {
        onClickHandler(tgfFormContext);
    };

    const isDisabled = disabledFunction(tgfFormContext);
    const content = contentFunction(tgfFormContext);

    if (!isVisibleFunction(tgfFormContext)) {
        return null;
    }

    const button = (
        <Button
            variant={variant}
            color={color}
            type={type}
            disabled={isDisabled}
            onClick={internalOnClick}
        >
            {content}
        </Button>
    );

    if (mouseOverTooltipContent) {
        return <Tooltip title={mouseOverTooltipContent}>{button}</Tooltip>;
    }

    return button;
};

export default TgfButtonFormField;
