import React from "react";
import {Box, Typography} from "@material-ui/core";
import './AgentRewardsQualifiers.scss';

const AgentRewardsQualifiers = ({ title, qualifiers }) => {
    return <Box className={"agent-rewards-qualifiers"}>
            <Typography variant={"h5"}>{title}</Typography>
            {qualifiers && qualifiers.map((qualifier) => <Box><Typography variant={"h5"}>{qualifier}</Typography></Box>)}
        </Box>;
};

export default AgentRewardsQualifiers;