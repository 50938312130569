import React from "react";
import { Label } from "@progress/kendo-react-labels";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import FieldError from "../../../../../crm/components/inputs/FieldError";
import InfoIconTooltip from "../../../../components/Tooltips/InfoIconTooltip/InfoIconTooltip";
import FindByPath from "../utils/FindByPath"
import {TgfFormContext} from "../TgfFormContext";

const TgfDropDownListFormField = ({
                                      tgfFormContext = new TgfFormContext({}),
                                      id = 'drop-down-list',
                                      name = 'drop-down-list',
                                      label = 'DropDownList',
                                      bindingValue = 'entity.value',
                                      validationBindingValue = 'entity.value',
                                      dataBindingValue = 'entity.additionalData.value',
                                      required = false,
                                      defaultValue = null,
                                      toolTipText = null,
                                      disabledFunction = (tgfFormContext) => false,
                                      isVisibleFunction = (tgfFormContext) => true,
                                  }) => {

    // Helper function to get data binding value
    const getDataBindingValue = () => {
        return FindByPath(tgfFormContext.formData.data, dataBindingValue); // assuming findByPath is available
    };

    // Helper function to get the selected item based on the binding value
    const getIdDataBindingValue = () => {
        let idValue = FindByPath(tgfFormContext.formData.data, bindingValue);
        if ((idValue === 0 || idValue == null) && defaultValue > 0) {
            idValue = defaultValue;
        }

        const pairs = getDataBindingValue();
        return pairs.find((obj) => obj.id === idValue);
    };

    // Event handler for when the value changes
    const handleStringIdChange = (e) => {
        tgfFormContext.formData.setValue(bindingValue, e.target.value?.id);
    };

    // Check visibility using the provided function
    if (!isVisibleFunction(tgfFormContext)) {
        return null;
    }

    return (
        <>
            <Label editorId={id}>{label}</Label>
            {toolTipText && <InfoIconTooltip title={toolTipText} />}
            <DropDownList
                id={id}
                name={name}
                data={getDataBindingValue()}
                textField="display"
                dataItemKey="id"
                value={getIdDataBindingValue()}
                onChange={handleStringIdChange}
                style={{ width: "100%" }}
                disabled={disabledFunction(tgfFormContext)}
            />
            <FieldError shouldRender={tgfFormContext.formMode.isEditMode}>{FindByPath(tgfFormContext.formValidation.validationErrors, validationBindingValue)}</FieldError>
        </>
    );
};

export default TgfDropDownListFormField;
