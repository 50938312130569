import {AppBar, Button, Hidden, Menu, MenuItem, Toolbar} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {Menu as MenuIcon, Person} from '@material-ui/icons';
import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import Core from "@atomos/core";
import CrmSearchAllComboBox from "../../../crm/widgets/catch-all-search/CrmSearchAllComboBox";
import useIsAdministrator from "../../../hubs/personaV2/selectors/useIsAdministrator";
import ComponentBuilder from '../../ComponentBuilder';
import TopMenu from '../../layouts/includes/Menu/TopMenu';
import TgfLogo from '../../media/TgfLogo.svg';
import AppDrawer from '../AppDrawer';
import './AppNavBar.scss';
import AppNavDrawer from './AppNavDrawer';
import isHeadOfAgency from "../../../hubs/personaV2/selectors/useIsHeadOfAgency";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingLeft: 5,
        paddingRight: 15,
    },
    title: {
        flexGrow: 1,
        display: 'flex',
    },
    newItem: {
        color: theme.palette.tgfBlack.main,
        '&:hover': {
            color: theme.palette.tgfBlack.main,
        },
    },
    navButtons: {
        textDecoration: 'none',
        padding: 0,
        color: theme.palette.tgfBlack.main,
    },
    removeOutline: {
        color: theme.palette.white,
        outline: 'none',
        opacity: .7,
        '&:focus': {
            outline: 'none',
            opacity: 1,
        },
        '&:hover': {
            backgroundColor: theme.palette.tgfBlack.main,
            opacity: 1,
        },
    },
    menu: {
        marginLeft: 'auto',
    },
    buttonNoStyle: {
        backgroundColor: 'none',
        '&:hover': {
            backgroundColor: 'none',
        },
    },
    menuDropdown: {
        marginTop: 30,
    },
}));

const AppNavBar = (props) => {
    const classes = useStyles();

    const {
        associateName,
        logout,
        isAdmin,
        isHeadOfAgency,
    } = props;

    const [anchorEl, setAnchorEl] = useState(null);
    const [addOpen, setAddOpen] = useState(null);
    const openAddDropdown = Boolean(addOpen);
    const openDropdown = Boolean(anchorEl);
    const [toggleNav, setToggleNav] = useState(false);
    const currentLoc = window.location.pathname.split('/')[1];

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleProfileMenuClose = () => {
        setAnchorEl(null);
    };

    const handleLogoutClick = (e) => {
        localStorage.removeItem("employeeContext");
        logout();
        setAnchorEl(null);
    };
    const handleAddClose = () => {
        setAddOpen(null);
    };

    const handleAddMenu = (event) => {
        setAddOpen(event.currentTarget);
    };


    const handleToggleNav = () => setToggleNav(!toggleNav);
    const handleMenuToggle = () => setAddOpen(!addOpen);

    const navLinks = [
        {
            title: 'Load Board',
            key: 'shipment',
            url: '/shipment/listing/working',
            isRestrictedToAdminOnly: false,
            isRestrictedToFranchiseMode: false,
        },
        {
            title: 'Address Book',
            key: 'address-book',
            url: '/address-book/listing',
            isRestrictedToAdminOnly: false,
            isRestrictedToFranchiseMode: false,
        },
        {
            title: 'Carriers',
            key: 'carrier',
            url: '/carrier/listing',
            isRestrictedToAdminOnly: false,
            isRestrictedToFranchiseMode: false,
        },
        {
            title: 'Open Loads',
            key: 'open-loads-board',
            url: '/open-loads-board/shipments',
            isRestrictedToAdminOnly: false,
            isRestrictedToFranchiseMode: false,
        },
        {
            title: 'Reports',
            key: 'reports',
            url: '/reports',
            isRestrictedToAdminOnly: false,
            isRestrictedToFranchiseMode: false,
        },
        {
            title: 'Agency',
            key: 'agency',
            url: '/agency',
            isRestrictedToAdminOnly: false,
            isRestrictedToFranchiseMode: true,
        },
        {
            title: 'LTL Quote',
            key: 'ltl-quote',
            url: '/ltl-quote/customer',
            isRestricted: false,
        },
        {
            title: 'Admin',
            key: 'admin',
            url: '/admin',
            isRestrictedToAdminOnly: true,
            isRestrictedToFranchiseMode: false,
        },
        {
            title: 'Help',
            key: 'support',
            url: '/support/help',
            isRestrictedToAdminOnly: false,
            isRestrictedToFranchiseMode: false,
        },
        {
            title: 'Add',
            key: 'add',
            isRestrictedToAdminOnly: false,
            isRestrictedToFranchiseMode: false,
            handleAddMenu: handleAddMenu,
            openAddDropdown: openAddDropdown,
            handleAddClose: handleAddClose,
            addOpen: addOpen,
            submenus: [
                {
                    title: 'New Company',
                    url: '/address-book/new/details',
                },
                {
                    title: 'New Shipment',
                    url: '/shipment/new/details',
                },
            ],
        },
    ];


    return (
        <React.Fragment>
            <AppBar>
                <Toolbar className={classes.toolbar} disableGutters>
                    <Link to={'/'}>
                        <img src={TgfLogo} className={'navbarLogo'} alt="TallGrassFreight logo" />
                    </Link>
                    <Hidden lgUp>
                        <MenuIcon onClick={handleToggleNav} className={classes.menu} />
                    </Hidden>
                    <Hidden mdDown>
                        <div className={classes.title}>
                            <TopMenu
                                currentLoc={currentLoc}
                                navLinks={navLinks}
                                isAdmin={isAdmin}
                                isHeadOfAgency={isHeadOfAgency}
                            />
                        </div>
                        <CrmSearchAllComboBox filterMap={Core.Utils.identity}></CrmSearchAllComboBox>
                        <Button
                            onClick={handleProfileMenuOpen} className={classes.removeOutline} endIcon={<Person />}
                        >
                            {associateName}
                        </Button>
                        <Menu
                            id="logout"
                            anchorEl={anchorEl}
                            keepMounted
                            open={openDropdown}
                            onClose={handleProfileMenuClose}
                            className={classes.menuDropdown}
                        >
                            <MenuItem onClick={handleLogoutClick}>Log Out</MenuItem>
                        </Menu>
                    </Hidden>
                </Toolbar>
            </AppBar>
            <AppDrawer open={toggleNav} toggle={handleToggleNav} onClose={handleToggleNav} anchor="right">
                <div>
                    <AppNavDrawer
                        navLinks={navLinks}
                        logout={handleLogoutClick}
                        handleMenuToggle={handleMenuToggle}
                        addOpen={addOpen}
                        isAdmin={isAdmin}
                    />
                </div>
            </AppDrawer>
        </React.Fragment>
    );

};

export default ComponentBuilder
    .wrap(AppNavBar)
    .stateToProps((state, ownProps) => {
        const associate = state.persona.associate;
        return {
            isAdmin: useIsAdministrator(),
            isHeadOfAgency: isHeadOfAgency(),
            associateName: `${associate.firstName} ${associate.lastName}`,
        };
    })
    .dispatchToProps((shell, dispatch, getState) => {
        return {
            async logout() {
                dispatch(await shell.actions.persona.invalidate());
            },
            async getFeatureState() {
                return await shell.gateway.communicator.get('/security/featureFlags');
            },
        };
    })
    .build();
