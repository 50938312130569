import React, {useState, useEffect} from "react";
import {Grid} from "@material-ui/core";
import ComponentBuilder from "../../../core/ComponentBuilder";
import FullWidthLayout from "../../../core/layouts/FullWidthLayout";
import useIsAdministrator from "../../../hubs/personaV2/selectors/useIsAdministrator";
import LeftNav from "../../admin/LeftNav";
import AppMuiCheckbox from "../../../core/components/inputs/AppCheckbox";
import AgentRewardsListingTable from "./includes/AgentRewardsListingTable";
import AppMonthYear from "../../../core/components/inputs/AppDatePicker/AppMonthYear";
import NumberFormat from "react-number-format";
import AppPercentText from "../../../core/components/text/AppPercentText";
import AgentTooltipComponent from "../../../crm/components/agent-tooltip/AgentTooltipComponent";
import {usePageTitle} from "../../../crm/components/customHooks/misc/usePageTitle";
import ReportNav from "../ReportNav";
import AgentRewardsQualifiers from "./includes/AgentRewardsQualifiers";

const loadProcessName = "admin.agent-rewards-program-report";

const AgentRewardsProgramsReport = (props) => {

    usePageTitle("TGF: Agent Rewards Program Report");

    const renderPercent = (value) => {
        if (!value) return null;

        return <AppPercentText value={value} />;
    };

    const renderPacePercent = (value) => {
        if (!value) return null;

        return (
          <>
              <span>Pacing </span>
              <AppPercentText
                value={value}
              />
          </>
        );
    };
    const renderCurrencyText = (value) => {
        return <NumberFormat
                displayType={"text"}
                thousandSeparator={","}
                decimalScale={2}
                fixedDecimalScale
                prefix={"$"}
                value={value} />
    };


    const {associate, isAdmin, load, loadQualifiers} = props;

    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(20);
    const [sort, setSort] = useState([["firstName", "asc"]]);
    const [order, setOrder] = useState("desc");
    const [orderBy, setOrderBy] = useState("bolNumber");
    const [isAllAssociates, setIsAllAssociates] = React.useState(false);
    const [selectedYear, setSelectedYear] = React.useState(new Date());
    const [data, setData] = useState([]);
    const [qualifierData, setQualifierData] = useState();

    const [gridColumns, setGridColumns] = useState([
        {
            title: "First Name",
            field: "firstName",
            styles: {
                width: 50,
            },
            dataType: 'component',
            component: ({ rowData: record }) => {
                return (
                  <AgentTooltipComponent content={record.firstName} agentId={record.associateID}/>
                );
            },
        },
        {
            title: "Last Name",
            field: "lastName",
            styles: {
                width: 50,
            },
            dataType: 'component',
            component: ({ rowData: record }) => {
                return (
                  <AgentTooltipComponent content={record.lastName} agentId={record.associateID}/>
                );
            },
        },
        {
            title: "Current YTD GM",
            field: "totmargin",
            styles: {
                width: 50,
                color: "blue",
            },
            dataType: "currency",
        },
        {
            title: "% To Club 250 ",
            field: "twoHundred",
            styles: {
                width: 50,
                color: "blue",
            },
            dataType: "component",
            component: (row) => renderPercent(row.rowData["twoHundred"]),
        },
        {
            title: "% To Diamond ",
            field: "diamond",
            styles: {
                width: 50,
                color: "blue",
            },
            dataType: "component",
            component: (row) => renderPercent(row.rowData["diamond"]),
        },
        {
            title: "% To Elite ",
            field: "elite",
            styles: {
                width: 50,
                color: "blue",
            },
            dataType: "component",
            component: (row) => renderPercent(row.rowData["elite"]),
        },
        {
            title: "Year-end GM Pace",
            field: "pace",
            styles: {
                width: 50,
                color: "darkgreen",
            },
            dataType: "component",
            component: (row) => renderCurrencyText(row.rowData["pace"]),
        },
        {
            title: "% To Club 250",
            field: "club250pace",
            styles: {
                width: 50,
                color: "darkgreen",
            },
            dataType: "component",
            component: (row) => renderPacePercent(row.rowData["club250pace"]),
        },
        {
            title: "% To Diamond",
            field: "diamondpace",
            styles: {
                width: 50,
                color: "darkgreen",
            },
            dataType: "component",
            component: (row) => renderPacePercent(row.rowData["diamondpace"]),
        },
        {
            title: "% To Elite",
            field: "elitepace",
            styles: {
                width: 50,
                color: "darkgreen",
            },
            dataType: "component",
            component: (row) => renderPacePercent(row.rowData["elitepace"]),
        },
    ]);



    //Handles when the user changes pages within the table.
    const handlePageChange = (e, page) => {
        setOffset(page * limit);
    };

    const handleYearChange = (YearValue) => {
        if (YearValue.isValid()) {
            setSelectedYear(YearValue.toDate());


        }
    };

    useEffect(() => {
        const current = new Date();

        if (selectedYear.getFullYear() !== current.getFullYear()) {
            setGridColumns([
                {
                    title: "First Name",
                    field: "firstName",
                    styles: {
                        width: 50,
                    },
                    dataType: 'component',
                    component: ({ rowData: record }) => {
                        return (
                          <AgentTooltipComponent content={record.firstName} agentId={record.associateID}/>
                        );
                    },
                },
                {
                    title: "Last Name",
                    field: "lastName",
                    styles: {
                        width: 50,
                    },
                    dataType: 'component',
                    component: ({ rowData: record }) => {
                        return (
                          <AgentTooltipComponent content={record.lastName} agentId={record.associateID}/>
                        );
                    },
                },
                {
                    title: "Current YTD GM",
                    field: "totmargin",
                    styles: {
                        width: 50,
                        color: "blue",
                    },
                    dataType: "currency",
                },
                {
                    title: "% To Club 250 ",
                    field: "twoHundred",
                    styles: {
                        width: 50,
                        color: "blue",
                    },
                    dataType: "component",
                    component: (row) => renderPercent(row.rowData["twoHundred"]),
                },
                {
                    title: "% To Diamond ",
                    field: "diamond",
                    styles: {
                        width: 50,
                        color: "blue",
                    },
                    dataType: "component",
                    component: (row) => renderPercent(row.rowData["diamond"]),
                },
                {
                    title: "% To Elite ",
                    field: "elite",
                    styles: {
                        width: 50,
                        color: "blue",
                    },
                    dataType: "component",
                    component: (row) => renderPercent(row.rowData["elite"]),
                },
            ]);
        } else {
            setGridColumns([
                {
                    title: "First Name",
                    field: "firstName",
                    styles: {
                        width: 50,
                    },
                    dataType: 'component',
                    component: ({ rowData: record }) => {
                        return (
                          <AgentTooltipComponent content={record.firstName} agentId={record.associateID}/>
                        );
                    },
                },
                {
                    title: "Last Name",
                    field: "lastName",
                    styles: {
                        width: 50,
                    },
                    dataType: 'component',
                    component: ({ rowData: record }) => {
                        return (
                          <AgentTooltipComponent content={record.lastName} agentId={record.associateID}/>
                        );
                    },
                },
                {
                    title: "Current YTD GM",
                    field: "totmargin",
                    styles: {
                        width: 50,
                        color: "blue",
                    },
                    dataType: "currency",
                },
                {
                    title: "% To Club 250 ",
                    field: "twoHundred",
                    styles: {
                        width: 50,
                        color: "blue",
                    },
                    dataType: "component",
                    component: (row) => renderPercent(row.rowData["twoHundred"]),
                },
                {
                    title: "% To Diamond ",
                    field: "diamond",
                    styles: {
                        width: 50,
                        color: "blue",
                    },
                    dataType: "component",
                    component: (row) => renderPercent(row.rowData["diamond"]),
                },
                {
                    title: "% To Elite ",
                    field: "elite",
                    styles: {
                        width: 50,
                        color: "blue",
                    },
                    dataType: "component",
                    component: (row) => renderPercent(row.rowData["elite"]),
                },
                {
                    title: "Year-end GM Pace",
                    field: "pace",
                    styles: {
                        width: 50,
                        color: "darkgreen",
                    },
                    dataType: "component",
                    component: (row) => renderCurrencyText(row.rowData["pace"]),
                },
                {
                    title: "% To Club 250",
                    field: "club250pace",
                    styles: {
                        width: 50,
                        color: "darkgreen",
                    },
                    dataType: "component",
                    component: (row) => renderPacePercent(row.rowData["club250pace"]),
                },
                {
                    title: "% To Diamond",
                    field: "diamondpace",
                    styles: {
                        width: 50,
                        color: "darkgreen",
                    },
                    dataType: "component",
                    component: (row) => renderPacePercent(row.rowData["diamondpace"]),
                },
                {
                    title: "% To Elite",
                    field: "elitepace",
                    styles: {
                        width: 50,
                        color: "darkgreen",
                    },
                    dataType: "component",
                    component: (row) => renderPacePercent(row.rowData["elitepace"]),
                },
            ]);
        }
    }, [selectedYear]);

    useEffect(() => {
        load(associate.id, sort, offset, limit, isAllAssociates, selectedYear, isAdmin).then(
          (data) => {
              setData(data);
          }
        );
    }, [sort, offset, limit, isAllAssociates, selectedYear]);

    useEffect(() => {
        loadQualifiers(isAllAssociates, selectedYear).then((data) => setQualifierData(data));
    }, [isAllAssociates, selectedYear]);

    // Handles when the user clicks on column headers for sorting.
    const handleSortChange = (column) => {
        const changeOrder =
          order === "asc" && sort[0][0] === column ? "desc" : "asc";

        setSort([[column, changeOrder]]);
        setOrder(changeOrder);
        setOrderBy(column);
    };
    const handleChangeRowsPerPage = (e) => {
        setOffset(0);
        setLimit(e.target.value);
    };

    const handleCheckboxChange = () => {
        setIsAllAssociates(isAllAssociates === false);
        setOffset(0);
    };

    const getQualifierData = (qualifier) => {
        return qualifierData
            ? qualifierData.breakdowns
                .filter(d => d[qualifier] >= 1)
                .map(d => d.llcName)
                .sort()
            : [];
    };

    return (
      <FullWidthLayout SideNav={ReportNav} title="Agent Rewards Programs Report">
          <Grid container spacing={1} direction="column">
              <Grid item>
                  <Grid container spacing={2} alignItems="flex-end">
                      <Grid item xs={12} md={2}>
                          <label htmlFor="MonthYear">Month/Year:</label>
                          <AppMonthYear
                            id="Year"
                            openTo="year"
                            views={["year"]}
                            inputVariant="outlined"
                            value={selectedYear}
                            onChange={handleYearChange}
                          />
                      </Grid>
                      {isAdmin && <Grid item>
                          <AppMuiCheckbox
                            label={"View all/past agents"}
                            checked={isAllAssociates}
                            onChange={handleCheckboxChange}
                          ></AppMuiCheckbox>
                      </Grid>}
                  </Grid>
              </Grid>
              <Grid item>
                  {data && (
                    <AgentRewardsListingTable
                      count={{}}
                      data={data}
                      orderBy={orderBy}
                      order={order}
                      rowsPerPage={limit}
                      page={offset / limit}
                      onChangePage={handlePageChange}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                      onSort={handleSortChange}
                      gridColumns={gridColumns}
                    />
                  )}
              </Grid>
              <Grid item>
                  {qualifierData && <Grid container key={qualifierData.count}>
                      <Grid item xs={3}>
                          <AgentRewardsQualifiers title={"Club 250 Qualifiers"} qualifiers={getQualifierData('twoHundred')} />
                      </Grid>
                      <Grid item xs={3}>
                          <AgentRewardsQualifiers title={"Diamond Club Qualifiers"} qualifiers={getQualifierData('diamond')} />
                      </Grid>
                      <Grid item xs={3}>
                          <AgentRewardsQualifiers title={"Elite Club Qualifiers"} qualifiers={getQualifierData('elite')} />
                      </Grid>
                  </Grid>}
              </Grid>
          </Grid>
      </FullWidthLayout>
    );
};
export default ComponentBuilder.wrap(AgentRewardsProgramsReport)
  .stateToProps((state, ownProps) => {
      return {
          associate: state.persona.associate,
          isAdmin: useIsAdministrator(),
      };
  })
  .dispatchToProps((shell, dispatch, getState) => {
      return {
          async load(
            associateId,
            sort,
            offset,
            limit,
            loadAllAssociates,
            selectedYear,
            isAdmin
          ) {
              dispatch(shell.actions.sys.processStart(loadProcessName));
              const year = new Date(selectedYear).getFullYear();

              const searchOptions = {
                  filter: {
                      shipmentDate: year.toString(),
                  },
                  offset: offset,
                  limit: limit,
                  sort: sort,
              };

              if (!loadAllAssociates) {
                  searchOptions.filter.isActive = true;
              }

              if (!isAdmin) {
                  searchOptions.filter.associateId = associateId;
              }

              const results = await shell.gateway.calculateYearlyMarginGoalsAll(
                associateId,
                searchOptions
              );
              dispatch(shell.actions.sys.processComplete(loadProcessName));
              return results;
          },
          async loadQualifiers(
              loadAllAssociates,
              selectedYear
          ) {
              dispatch(shell.actions.sys.processStart(loadProcessName));
              const year = new Date(selectedYear).getFullYear();

              const searchOptions = {
                  filter: { shipmentDate: year.toString() },
              };

              if (!loadAllAssociates) {
                  searchOptions.filter.isActive = true;
              }

              const results = await shell.gateway.calculateYearlyQualifiers(
                  searchOptions
              );
              dispatch(shell.actions.sys.processComplete(loadProcessName));
              return results;
          },
      };
  })
  .build();
