import React, {useState} from "react";
import {Grid} from "@material-ui/core";

import FullWidthLayout from "../../../core/layouts/FullWidthLayout";
import PayConfigurationCard from "./includes/PayConfigurationCard";
import Adjustments from "./includes/Adjustments";
import ReportNav from "../ReportNav";
import AppCard from "../../../core/components/cards/AppCard";
import LegendCheckMark from "./includes/LegendCheckMark";
import "./MonthlyGrossMargin.css";
import CommissionBreakdownCard from "./includes/CommissionBreakdownCard";
import CrmAssociateDropDown from "../../../crm/components/CrmAssociateDropDown";
import ComponentBuilder from "../../../core/ComponentBuilder";
import useIsAdministrator from "../../../hubs/personaV2/selectors/useIsAdministrator";
import CommissionTypeNames from "../../../hubs/commission/CommissionTypeNames";
import CommissionTypeLabels from "../../../crm/components/associate/CommissionsCenter/CommissionTypeLabels";
import AppMonthYear from "../../../core/components/inputs/AppDatePicker/AppMonthYear";
import GrossMarginTable from "../../../crm/components/associate/GrossMarginTable";
import TotalMarginTable from "./includes/TotalMarginTable";
import ShipmentListing from "./includes/ShipmentListing";
import Business from "@tgf-crm/business";
import TotalMarginPercentage from "./includes/TotalMarginPercentage";
import TotalMarginPaceTable from "../../../crm/components/tables/TotalMarginPaceTable";
import TotalRevenuePaceTable from "../../../crm/components/tables/TotalRevenuePaceTable";
import moment from "moment";
import {usePageTitle} from "../../../crm/components/customHooks/misc/usePageTitle";
import AccessDeniedPage from "../../errors/AccessDeniedPage";
import TgfAgencyComboBox from "../../../core/kendo/form/inputs/specialty/TgfAgencyComboBox";
import InfoIconTooltip from "../../../core/components/Tooltips/InfoIconTooltip/InfoIconTooltip";
import TgfAgencyAssociatesComboBox, {
    TgfAgencyAssociateComboBoxFilters
} from "../../../core/kendo/form/inputs/specialty/TgfAgencyAssociatesComboBox";
import {Checkbox} from "@progress/kendo-react-inputs";
import AgentRankDialog from "./includes/AgentRankDialog";
import useIsHeadOfAgency from "../../../hubs/personaV2/selectors/useIsHeadOfAgency";

const LoadProcessName = "Report.GrossMargin.Load";
const LoadShipmentsProcessName = "Report.GrossMargin.LoadShipments";
const LoadAdjustmentsProcessName = "Report.GrossMargin.LoadAdjustments";

const PercentFormatter = new Intl.NumberFormat("en-US", {
    style: "percent",
    minimumFractionDigits: 1,
});

const computePayPeriodShipment =
    (allCommissionRates) => (commissionShipment) => {
        let totalMargin = 0;
        let earnedPayout = 0;

        const payPeriodCommission = Business.Associate.PayPeriod.buildFromDate(
            new Date(commissionShipment.actualDeliveryDate)
        );
        const commissionRate = allCommissionRates.find(
            (commRate) =>
                commRate.commissionDate.toMoment().utc().year() ===
                payPeriodCommission.startDate.toMoment().utc().year() &&
                commRate.commissionDate.toMoment().utc().month() ===
                payPeriodCommission.startDate.toMoment().utc().month() &&
                commRate.commissionDate.toMoment().utc().date() ===
                payPeriodCommission.startDate.toMoment().utc().date()
        );

        if (commissionRate) {
            const commissionCalculator = new Business.Associate.CommissionCalculator(
                commissionRate
            );
            earnedPayout = commissionCalculator.calculate(commissionShipment);
        }
        totalMargin =
            Business.Shipment.ShipmentMath.grossMargin(commissionShipment);
        commissionShipment.fee =
            earnedPayout && totalMargin ? totalMargin - earnedPayout : null;
        commissionShipment.totalMargin = totalMargin || null;
        commissionShipment.earnedPayout = earnedPayout;

        return commissionShipment;
    };

const coerceCommissionShipment =
    (freightCategoryTypes, payPeriods) => (shipment) => {
        const commissionShipment = {};

        const categoryType = freightCategoryTypes.find(
            (fc) => fc.id === shipment.freightCategoryId
        );

        commissionShipment.id = shipment.id;
        commissionShipment.bolNumber = shipment.bolNumber;
        commissionShipment.adjustedCarrierCost =
            shipment.invoice?.adjustedCarrierCost;
        commissionShipment.adjustedCustomerCost =
            shipment.invoice?.adjustedCustomerCost;
        commissionShipment.loadType = categoryType.name;
        commissionShipment.carrierName = shipment.carrier?.name || null;
        commissionShipment.thirdPartyName = shipment.thirdParty?.name || null;
        commissionShipment.bolDate = shipment.bolDate;
        commissionShipment.customerName = shipment.customer?.name || null;
        commissionShipment.actualDeliveryDate =
            shipment.invoice?.actualDeliveryDate || null;
        commissionShipment.repPaid = shipment.invoice?.associateWasPaid || null;
        commissionShipment.mcNumber = shipment.carrier?.mcNumber || null;
        commissionShipment.companyId = shipment.customer?.id || null;
        commissionShipment.fee = null;
        commissionShipment.totalMargin = null;
        commissionShipment.earnedPayout = null;
        if (commissionShipment.actualDeliveryDate) {
            const payPeriodIndex = payPeriods.findIndex(
                (pp) =>
                    pp.startDate <= commissionShipment.actualDeliveryDate &&
                    pp.endDate >= commissionShipment.actualDeliveryDate
            );
            commissionShipment.periodMark =
                payPeriodIndex === -1
                    ? "black"
                    : payPeriodIndex === 0
                        ? "green"
                        : "blue";
        }

        return commissionShipment;
    };


const computeUtcDateRange = (date) => {
    let newDate = new Date();
    if (date) {
        newDate = date;
    }
    const utcStartDate = moment.utc([newDate.getFullYear(), newDate.getMonth(), 1, 0]).toDate();
    const utcEndDate = utcStartDate
        .toMoment()
        .utc()
        .add(1, "month")
        .subtract(1, "ms")
        .toDate();
    return [utcStartDate, utcEndDate];
};

const getPayPeriods = (date) => {
    const payPeriods = Business.Associate.PayPeriod.buildFromMonth(
        date.toMoment().utc().month(),
        date.toMoment().utc().year()
    );
    return payPeriods;
};

const isAdjustmentPaid = (adjustment) =>
    adjustment.repPaid > 0 || adjustment.repPaidConfirmed > 0;

const toDateString = (date, ignoreUtc = false) =>
    ignoreUtc
        ? date.toMoment().format("YYYY-MM-DD")
        : date.toMoment().utc().format("YYYY-MM-DD");

const adjustmentDatesAreEqual = (adjustmentDate, payPeriodDate) => {
    return toDateString(adjustmentDate, true) === toDateString(payPeriodDate);
};

const MonthlyGrossMarginPage = (props) => {
    const {
        isAdmin,
        isHeadOfAgency,
        currentAssociate,
        associateCommissionRates,
        grossMarginBreakdown,
        commissionBreakdown,
        freightCategoryTypes,
        adjustments,
        adjustmentCount,
        shipments,
        shipmentCount,
        load,
        loadAdjustments,
        loadShipments,
        dispose,
        navMenu,
        agencyMode
    } = props;

    usePageTitle(!agencyMode ? "TGF: Monthly GM Report" : "TGF: Agency GM Report");

    const employeeContext = JSON.parse(localStorage.getItem("employeeContext") ?? null);
    const canView = (employeeContext?.claims?.isHeadOfAgency) || (employeeContext?.claims?.isAdministrator);


    let [utcStartDate, utcEndDate] = computeUtcDateRange();
    const [associate, setAssociate] = React.useState(
        isAdmin ? null : currentAssociate
    );
    const [agency, setAgency] = React.useState(null);
    const [monthYear, setMonthYear] = React.useState(new Date());
    const [startDate, setStartDate] = React.useState(utcStartDate);
    const [endDate, setEndDate] = React.useState(utcEndDate);
    const [shipmentOffset, setShipmentOffset] = React.useState(0);
    const [shipmentLimit, setShipmentLimit] = React.useState(20);
    const [shipmentSort, setShipmentSort] = React.useState([
        ["bolNumber", "asc"],
    ]);
    const [shipmentOrder, setShipmentOrder] = React.useState("asc");
    const [shipmentOrderBy, setShipmentOrderBy] = React.useState("bolNumber");
    const [adjustmentOffset, setAdjustmentOffset] = React.useState(0);
    const [adjustmentLimit, setAdjustmentLimit] = React.useState(20);
    const [adjustmentSort, setAdjustmentSort] = React.useState([["id", "desc"]]);

    const [associateFilter, setAssociateFilter] = useState(null);
    const [hideCustomerOwnerShipments, setHideCustomerOwnerShipments] = useState(false);
    const [showWarDialog, setShowWarDialog] = useState(false);
    const payPeriods = getPayPeriods(startDate);

    React.useEffect(() => () => dispose(), []);

    React.useEffect(() => {
        if (associate) {
            load(
                associate.id,
                startDate,
                endDate,
                hideCustomerOwnerShipments,
                associateFilter
            );
        } else {
            dispose();
        }
    }, [
        startDate,
        endDate,
        associate,
        load,
        hideCustomerOwnerShipments,
        associateFilter]);

    React.useEffect(() => {
        if (associate) {
            loadShipments(
                associate.id,
                startDate,
                endDate,
                shipmentOffset,
                shipmentLimit,
                shipmentSort,
                hideCustomerOwnerShipments,
                associateFilter
            );
        }
    }, [
        startDate,
        endDate,
        associate,
        shipmentOffset,
        shipmentLimit,
        shipmentSort,
        loadShipments,
        hideCustomerOwnerShipments,
        associateFilter,
    ]);

    React.useEffect(() => {
        const load = async () => {
            if (associate) {

                const agency = await window.shell.gateway.getAssociate(associate.parentAssociateId, false);
                setAgency(agency);

                loadAdjustments(
                    associate.id,
                    payPeriods[0].startDate,
                    payPeriods[1].startDate,
                    adjustmentOffset,
                    adjustmentLimit,
                    adjustmentSort
                );
            }
        }
        load();
    }, [
        startDate,
        endDate,
        associate,
        adjustmentOffset,
        adjustmentLimit,
        adjustmentSort,
    ]);

    //Handles when the user changes pages within the table.
    const handleShipmentPageChange = (e, page) => {
        setShipmentOffset(page * shipmentLimit);
    };

    // Handles when the user clicks on column headers for sorting.
    const handleShipmentSortChange = (column) => {
        const [[columnName, order]] = shipmentSort;
        const changeOrder =
            order === "asc" && columnName === column ? "desc" : "asc";

        setShipmentSort([[column, changeOrder]]);
        setShipmentOrder(changeOrder);
        setShipmentOrderBy(column);
    };

    const handleShipmentLimitChange = (e) => {
        setShipmentOffset(0);
        setShipmentLimit(e.target.value);
    };

    //Handles when the user changes pages within the table.
    const handleAdjustmentPageChange = (e, page) => {
        setAdjustmentOffset(page * adjustmentLimit);
    };

    // Handles when the user clicks on column headers for sorting.
    const handleAdjustmentSortChange = (column) => {
        const [[columnName, order]] = adjustmentSort;
        const changeOrder =
            order === "asc" && columnName === column ? "desc" : "asc";

        setAdjustmentSort([[column, changeOrder]]);
    };

    const handleAdjustmentLimitChange = (e) => {
        setAdjustmentOffset(0);
        setAdjustmentLimit(e.target.value);
    };

    const handleMonthYearChange = (monthYearValue) => {
        const date =
            monthYearValue && monthYearValue.isValid()
                ? monthYearValue.toDate()
                : null;

        let [utcStartDate, utcEndDate] = computeUtcDateRange(date);
        setMonthYear(date);
        setStartDate(utcStartDate);
        setEndDate(utcEndDate);
        setShipmentOffset(0);
        setAdjustmentOffset(0);
    };

    const handleAssociateChange = (associate) => {
        setAssociate(associate);
        setShipmentOffset(0);
        setAdjustmentOffset(0);
    };

    const periodConfigurations = payPeriods
        .map((pp) => {
            // Large ass date comparison due to shitty timezone conversion. Be careful messing with this.
            return associateCommissionRates.find(
                (commRate) =>
                    commRate.commissionDate.toMoment().utc().year() ===
                    pp.startDate.toMoment().utc().year() &&
                    commRate.commissionDate.toMoment().utc().month() ===
                    pp.startDate.toMoment().utc().month() &&
                    commRate.commissionDate.toMoment().utc().date() ===
                    pp.startDate.toMoment().utc().date()
            );
        })
        .map(periodConfigurationMap);

    const computedShipments = shipments
        .map(coerceCommissionShipment(freightCategoryTypes, payPeriods))
        .map(computePayPeriodShipment(associateCommissionRates));

    const adjustmentRecords = adjustments
        .map((adjustment) => {
            const payPeriodIndex = payPeriods.findIndex((pp) =>
                adjustmentDatesAreEqual(adjustment.startDate, pp.startDate)
            );

            if (payPeriodIndex < 0) return null;

            const repPaidPeriod = payPeriodIndex === 0 ? "green" : "blue";

            return {
                ...adjustment,
                payPeriodDates: payPeriods[payPeriodIndex].toString(),
                repPaid: isAdjustmentPaid(adjustment),
                repPaidPeriod,
            };
        })
        .filter((x) => x);

    const onSelectAssociate = (e, type) => {
        const associate = e?.target?.value ? e?.target?.value : e;
        if (!associate?.id) {
            setAssociateFilter(null);

            setShipmentOffset(0);
            setShipmentLimit(20);
            setShipmentSort([["bolNumber", "asc"],]);

            setAdjustmentOffset(0);
            setAdjustmentLimit(20);
            setAdjustmentSort([["id", "desc"]]);

            return;
        }
        switch (type) {
            case "agency customer owner":
                setAssociateFilter({agencyCustomerOwner: associate});
                break;
            case "agency any":
                setAssociateFilter({agencyAny: associate});
                break;
            case "any":
                setAssociateFilter({any: associate});
                break;
            default:
                setAssociateFilter(null);
        }

        setShipmentOffset(0);
        setShipmentLimit(20);
        setShipmentSort([["bolNumber", "asc"],]);

        setAdjustmentOffset(0);
        setAdjustmentLimit(20);
        setAdjustmentSort([["id", "desc"]]);
    };

    const handleHideCustomerOwnedShipments = (e) => {
        if (e.target.value) {
            setHideCustomerOwnerShipments(e.target.value);
        } else {
            setHideCustomerOwnerShipments(false);
        }

        setShipmentOffset(0);
        setShipmentLimit(20);
        setShipmentSort([["bolNumber", "asc"],]);

        setAdjustmentOffset(0);
        setAdjustmentLimit(20);
        setAdjustmentSort([["id", "desc"]]);
    };

    const handleSelectionOfAgency = async (e) => {
        if (e.target.value) {
            const targetAssociate = await window.shell.gateway.getAssociate(e.target.value, false);
            setAssociate(targetAssociate);
        } else {
            setAssociate(null);
        }

        setHideCustomerOwnerShipments(false);
        setAssociateFilter(null);

        setShipmentOffset(0);
        setShipmentLimit(20);
        setShipmentSort([["bolNumber", "asc"],]);

        setAdjustmentOffset(0);
        setAdjustmentLimit(20);
        setAdjustmentSort([["id", "desc"]]);
    };

    const handleWarClick = () => setShowWarDialog(!showWarDialog);
    const showWarLink = () => monthYear && !agencyMode && (isHeadOfAgency || isAdmin);

    return (
        canView ? <FullWidthLayout
            SideNav={navMenu ? navMenu : ReportNav}
            title={!agencyMode ? "Monthly Gross Margin Report" : "Agency GM Report"}>
            <Grid container spacing={2}>
                {agencyMode &&
                    <Grid container item xs={12}>
                        <Grid container item spacing={2}>
                            {isAdmin &&
                                <Grid item xs={12} md={2}>
                                    <TgfAgencyComboBox
                                        label={null}
                                        onChange={handleSelectionOfAgency}
                                        value={associate?.id}
                                        franchiseMode={true}
                                        isDisabled={false}
                                    />
                                </Grid>
                            }
                            <Grid
                                item xs={12} md={2}
                                style={{
                                    alignItems: "center",
                                    display: "flex",
                                }}
                            >
                                <TgfAgencyAssociatesComboBox
                                    placeholder={'Filter by customer owners...'}
                                    onChange={(e) => onSelectAssociate(e, "agency customer owner")}
                                    selectedAgencyAssociate={associateFilter?.agencyCustomerOwner ?? null}
                                    activeOnly={true}
                                    visibilityPermissionIdFilter={2}
                                    actionRoleId={3}
                                    roleFilter={TgfAgencyAssociateComboBoxFilters.customerOwners}
                                    agencyId={!agencyMode ? associate?.id : associate?.parentAssociateId}
                                    disabled={Boolean(associateFilter?.agencyAny || hideCustomerOwnerShipments)}
                                />
                                <InfoIconTooltip
                                    title={
                                        <>
                                            <p>
                                                Selecting a user here will filter the page's information below to just
                                                their customers' shipment information.
                                            </p>
                                            <p>
                                                Inactive users will not display as an option on this dropdown, which is
                                                why working with the back office when terminating a Restricted user is
                                                important as they can make the transition/reassigning of accounts an
                                                easy process.
                                            </p>
                                        </>
                                    }
                                    style={{alignSelf: "center"}}
                                />
                            </Grid>
                            <Grid
                                item xs={12} md={2} style={{display: "flex", alignItems: "center", alignSelf: "center"}}
                            >
                                <Checkbox
                                    value={hideCustomerOwnerShipments}
                                    id={"hideCustomerOwnerShipments"}
                                    name={"hideCustomerOwnerShipments"}
                                    label={"Hide customer owner shipments"}
                                    onChange={handleHideCustomerOwnedShipments}
                                    disabled={associateFilter?.agencyCustomerOwner || associateFilter?.agencyAny}
                                />
                                <InfoIconTooltip
                                    title={
                                        <>
                                            <p>
                                                Checking this box will hide all information below involving customers
                                                who have a Customer Owner assigned to them - even those involving an
                                                inactive Restricted user.
                                            </p>
                                            <p>
                                                Working with the back office when terminating a Restricted user is
                                                important as they can make the transition/reassigning of accounts an
                                                easy process.
                                            </p>
                                        </>
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                }
                <Grid container item xs={12} md={isAdmin ? 3 : 2}>
                    <AppCard>
                        <Grid container item spacing={2}>
                            <Grid item xs={12} md={isAdmin && !agencyMode ? 6 : 12}>
                                <label htmlFor="MonthYear">Month/Year:</label>
                                <AppMonthYear
                                    id="monthYear"
                                    openTo="year"
                                    views={["year", "month"]}
                                    inputVariant="outlined"
                                    value={monthYear}
                                    onChange={handleMonthYearChange}
                                />
                            </Grid>
                            {(isAdmin && !agencyMode) && (
                                <Grid item xs={12} md={6}>
                                    <label htmlFor="associate">Associate</label>
                                    <CrmAssociateDropDown
                                        id={"associate"}
                                        autoSelectFirst={false}
                                        onChangeAssociate={handleAssociateChange}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </AppCard>
                </Grid>
                {showWarLink() && <Grid container item xs={1} spacing={2} className={"war-container"}>
                    <Grid item xs={12} onClick={handleWarClick}>WAR</Grid>
                    {showWarDialog &&
                        <AgentRankDialog
                            date={monthYear}
                            defaultAssociate={associate ?? currentAssociate}
                            onClose={handleWarClick} />}
                </Grid>}
                <Grid container xs={4} spacing={1} className={"margin-container"}>
                    <Grid item xs={12}>
                        <TotalMarginTable
                            date={monthYear}
                            grossMarginData={grossMarginBreakdown}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TotalMarginPercentage
                            date={monthYear}
                            grossMarginData={grossMarginBreakdown}
                        />
                    </Grid>
                </Grid>
                <Grid container xs={4} spacing={1} className={"margin-container"}>
                    <Grid item xs={12}>
                        <TotalMarginPaceTable
                            date={monthYear}
                            grossMarginData={grossMarginBreakdown}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TotalRevenuePaceTable
                            date={monthYear}
                            grossMarginData={grossMarginBreakdown}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{display: "flex"}}>
                    <GrossMarginTable
                        date={monthYear}
                        grossMarginData={grossMarginBreakdown}
                        agencyMode={agencyMode}
                    />
                </Grid>
                {!agencyMode &&
                    <>
                        <Grid item xs={12} md={5}>
                            <CommissionBreakdownCard commissionBreakdown={commissionBreakdown} />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <PayConfigurationCard periodConfigurations={periodConfigurations} />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <LegendCheckMark />
                        </Grid>
                    </>
                }
                {agencyMode &&
                    <Grid item xs={12} md={12}>
                        <LegendCheckMark agencyMode={agencyMode} />
                    </Grid>
                }
                <Grid item xs={12}>
                    <Adjustments
                        adjustments={adjustmentRecords}
                        adjustmentCount={adjustmentCount}
                        associate={agency}
                        offset={adjustmentOffset}
                        limit={adjustmentLimit}
                        sort={adjustmentSort}
                        onPageChange={handleAdjustmentPageChange}
                        onLimitChange={handleAdjustmentLimitChange}
                        onSortChange={handleAdjustmentSortChange}
                        isAdmin={isAdmin}
                        agencyMode={agencyMode}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ShipmentListing
                        offset={shipmentOffset}
                        limit={shipmentLimit}
                        sort={shipmentSort}
                        orderBy={shipmentOrderBy}
                        order={shipmentOrder}
                        shipments={computedShipments}
                        shipmentCount={shipmentCount}
                        onPageChange={handleShipmentPageChange}
                        onLimitChange={handleShipmentLimitChange}
                        onSortChange={handleShipmentSortChange}
                        agencyMode={agencyMode}
                    />
                </Grid>
            </Grid>
        </FullWidthLayout> : <AccessDeniedPage />
    );
};

const periodConfigurationMap = (commissionRate) => {
    const hasCommissionRate = commissionRate && commissionRate.id > 0;

    if (!hasCommissionRate) {
        return {
            paymentType: "N/A",
            commissionPercentage: "N/A",
            showFeeInformation: false,
        };
    }

    const isFeeCommissionType =
        commissionRate.commissionType === CommissionTypeNames.Fee;

    const commissionTypeKey = isFeeCommissionType
        ? CommissionTypeNames.Fee
        : CommissionTypeNames.Margin;

    const paymentType = CommissionTypeLabels.find(
        (label) => label.value === commissionTypeKey
    );

    const commissionPercentage = isFeeCommissionType
        ? commissionRate.feePercent
        : commissionRate.marginPercent;

    return {
        paymentType: paymentType.label,
        commissionPercentage: PercentFormatter.format(commissionPercentage),
        showFeeInformation: !isFeeCommissionType,
    };
};

export default ComponentBuilder.wrap(MonthlyGrossMarginPage)
    .stateToProps((state, ownProps) => ({
        associateCommissionRates:
        state.reporting.monthlyGrossMargin.associateCommissionRates,
        shipments: state.reporting.monthlyGrossMargin.shipments,
        shipmentCount: state.reporting.monthlyGrossMargin.shipmentCount,
        adjustments: state.reporting.monthlyGrossMargin.monthlyAdjustments,
        adjustmentCount: state.reporting.monthlyGrossMargin.monthlyAdjustmentCount,
        grossMarginBreakdown:
        state.reporting.monthlyGrossMargin.associateGrossMarginBreakdown,
        commissionBreakdown:
        state.reporting.monthlyGrossMargin.associateCommissionBreakdown,
        freightCategoryTypes: state.support.freightCategoryTypes,
        allCommissionRates: state.reporting.monthlyGrossMargin.allCommissionRate,
        currentAssociate: state.persona.associate,
        isAdmin: useIsAdministrator(),
        isHeadOfAgency: useIsHeadOfAgency()
    }))
    .dispatchToProps((shell, dispatch, getState) => {
        return {
            async load(associateId, startDate, endDate, hideCustomerOwnerShipments, associateFilter) {
                dispatch(shell.actions.sys.processStart(LoadProcessName));
                const actions = await Promise.all([
                    shell.actions.reporting.monthlyGrossMargin.loadAssociateMonthlyCommissionRates(
                        associateId,
                        startDate,
                        endDate
                    ),
                    shell.actions.reporting.monthlyGrossMargin.loadAssociateGrossMarginBreakdown(
                        associateId,
                        0,
                        startDate,
                        endDate,
                        hideCustomerOwnerShipments ? hideCustomerOwnerShipments : null,
                        associateFilter
                    ),
                    shell.actions.reporting.monthlyGrossMargin.loadAssociateMonthlyCommissionBreakdown(
                        associateId,
                        startDate,
                        endDate,
                        hideCustomerOwnerShipments,
                        associateFilter
                    ),
                ]);
                actions.forEach(dispatch);
                dispatch(shell.actions.sys.processComplete(LoadProcessName));
            },
            async loadAdjustments(
                associateId,
                startDate,
                endDate,
                offset,
                limit,
                sort
            ) {
                dispatch(shell.actions.sys.processStart(LoadAdjustmentsProcessName));
                dispatch(
                    await shell.actions.reporting.monthlyGrossMargin.loadAssociateMonthlyAdjustments(
                        associateId,
                        startDate,
                        endDate,
                        offset,
                        limit,
                        sort
                    )
                );
                dispatch(shell.actions.sys.processComplete(LoadAdjustmentsProcessName));
            },
            async loadShipments(
                associateId,
                startDate,
                endDate,
                offset,
                limit,
                sort,
                hideCustomerOwnerShipments,
                associateFilter
            ) {
                dispatch(shell.actions.sys.processStart(LoadShipmentsProcessName));
                const shipmentAction =
                    await shell.actions.reporting.monthlyGrossMargin.loadShipments(
                        associateId,
                        startDate,
                        endDate,
                        offset,
                        limit,
                        sort,
                        hideCustomerOwnerShipments,
                        associateFilter
                    );
                dispatch(shipmentAction);

                dispatch(shell.actions.sys.processComplete(LoadShipmentsProcessName));
            },
            async dispose() {
                dispatch(await shell.actions.reporting.monthlyGrossMargin.dispose());
            },
        };
    })
    .build();
