export class TgfFormContext
{
    constructor({
                    formData= {
                        data: null,
                        setData: null,
                        setValue: null,
                    },
                    formMethods = {
                        loadForm: null,
                        validateForm: null,
                        submitForm: null,
                    },
                    formValidation = {
                        validationErrors: null,
                        formIsValid: null,
                    },
                    formMode ={
                        isEditMode: null,
                        setIsEditMode: null,
                    },
                }) {
        this.formData = formData;
        this.formMethods = formMethods;
        this.formValidation = formValidation;
        this.formMode = formMode;
    }
}
