import React from "react";
import {useDispatch} from "react-redux";
import {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import "../tgf-form.scss";
import {TgfFormContext} from "./TgfFormContext";

const Form = (props) => {
    // Destructure the props to access children and any other props
    const {children, ...restProps} = props;

    // Function to recursively pass props to children and nested children
    const renderChildrenWithProps = (children) => {
        return React.Children.map(children, (child) => {
            if (React.isValidElement(child)) {
                // Clone the child and pass the restProps, also pass the children recursively
                return React.cloneElement(child, {
                    ...restProps,
                    children: renderChildrenWithProps(child.props.children), // Recursively pass down nested children
                });
            }
            return child; // Non-React elements (e.g., text) are returned as-is
        });
    };

    return <div>{renderChildrenWithProps(children)}</div>;
};

const TgfFormComponent = forwardRef(function TgfFormComponent(props, ref) {

    const {
        onLoadFormData,
        loadFormArgument,
        validationSchema,
        onSubmit,
        preRenderComponent,
        processName,
    } = props;

    const personalDispatch = useDispatch();

    const [data, setData] = useState({});
    const [isEditMode, setIsEditMode] = useState(false);
    const [validationErrors, setValidationErrors] = useState(null);
    const [isRenderable, setIsRenderable] = useState(false);

    useImperativeHandle(ref, () => ({
        async loadForm() {
            await loadForm();
        },
        async setValue(name, value) {
            await setValue(name, value);
        },
        async validateForm() {
            await validateFormData();
        },
        async setIsEditMode(value) {
            setIsEditMode(value);
        },
        async submitForm() {
            await onSubmit(data);
        },
    }));

    useEffect(() => {
        validateFormData();
    }, [data]);

    const assign = (obj, keyPath, value) => {
        // Split the path into components, handling brackets for array indices
        const keys = keyPath
            .replace(/\[(\d+)\]/g, '.$1') // Convert [index] to .index
            .split('.'); // Split by dot

        // Traverse and create the required structure
        keys.reduce((currentObj, key, index) => {
            // Convert key to number if it's an array index
            const indexKey = Number(key);
            const isArrayIndex = !isNaN(indexKey) && indexKey.toString() === key;

            // Determine if the next key exists and if it is an array index
            const nextKey = keys[index + 1];
            const isNextArrayIndex = nextKey != null && !isNaN(Number(nextKey)) && Number(nextKey).toString() === nextKey;

            if (index === keys.length - 1) {
                // If at the last key, assign the value
                if (isArrayIndex) {
                    // Ensure the target is an array before assignment
                    if (!Array.isArray(currentObj)) {
                        throw new Error('Target is not an array.');
                    }
                    // Extend the array if needed
                    while (currentObj.length <= indexKey) {
                        currentObj.push({});
                    }
                    // Assign the value
                    currentObj[indexKey] = value;
                } else {
                    currentObj[key] = value;
                }
            } else {
                // Ensure the current structure is valid for the next step
                if (currentObj == null) {
                    // Create the appropriate structure if it doesn't exist
                    currentObj = isArrayIndex ? [] : {};
                } else if (isArrayIndex) {
                    if (!Array.isArray(currentObj)) {
                        throw new Error('Expected an array but found an object.');
                    }
                    // Ensure the array has enough space
                    while (currentObj.length <= indexKey) {
                        currentObj.push({});
                    }
                    // Move deeper into the array
                    currentObj = currentObj[indexKey];
                } else {
                    if (typeof currentObj !== 'object' || Array.isArray(currentObj)) {
                        throw new Error('Expected an object but found an array or non-object.');
                    }
                    // Initialize the key if it doesn't exist
                    if (currentObj[key] == null) {
                        // Determine if the next key should be an array or object
                        currentObj[key] = isNextArrayIndex ? [] : {};
                    }
                    // Move deeper into the object
                    currentObj = currentObj[key];
                }
            }

            // Return the current object/array for the next iteration
            return currentObj;
        }, obj);
    };

    const loadForm = async () => {

        const returnData = {
            success: null,
            errorMessage: null,
        };

        let data = {};

        try {
            personalDispatch(window.shell.actions.sys.processStart(processName));

            try {
                data = await onLoadFormData(loadFormArgument);
                returnData.success = true;
            } catch (e) {
                returnData.success = false;
                returnData.errorMessage = e.message;
                data.error = e.message;
            }

            if (!returnData.success) {
                setIsRenderable(false);
            } else {
                setData(data);
                setIsRenderable(true);
            }
        } catch (e) {
            returnData.success = false;
            returnData.errorMessage = e.message;
            data.error = e.message;
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete(processName));
        }

        return returnData;
    };

    const validateFormData = async () => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('validating' + processName));
            try {
                if (validationSchema === null) return;
                await validationSchema.validate(data, {abortEarly: false});
                setValidationErrors(null);
            } catch (e) {
                const errors = {};
                for (let error of e.inner) {
                    assign(errors, error.path, error.message.replace(`${error.path}`, ""));
                }
                setValidationErrors(errors);
            }
        } catch (e) {
            console.log(e);
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('validating' + processName));
        }
    };

    const submitForm = async () => {
        await onSubmit(data);
    };

    const setValue = (path, value) => {
        assign(data, path, value);
        setData({...data});
    };

    const tgfFormContext = new TgfFormContext({
        formData: {
            data: data,
            setData: setData,
            setValue: setValue,
        },
        formMethods: {
            loadForm: loadForm,
            validateForm: validateFormData,
            submitForm: submitForm,
        },
        formValidation: {
            validationErrors: validationErrors,
            formIsValid: validationErrors === null,
        },
        formMode: {
            isEditMode: isEditMode,
            setIsEditMode: setIsEditMode,
        },
    });

    return (
        <>
            {
                isRenderable ?

                    <Form
                        tgfFormContext={tgfFormContext}
                        {...props}
                    >
                    </Form>

                    : [preRenderComponent]
            }
        </>
    );
});

export default TgfFormComponent;
