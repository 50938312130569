import React, {useEffect, useRef} from "react";
import TgfDialog from "../../../../core/kendo/dialog/TgfDialog";
import "./agencyUser.scss";
import TgfFormComponent from "../../../../core/kendo/form/tgfFormComponent/TgfFormComponent";
import {agencyUserSchema} from "./formValidationSchemas/agencyUserSchema";
import _ from "lodash";
import {useDispatch} from "react-redux";
import TgfDropDownListFormField
    from "../../../../core/kendo/form/tgfFormComponent/form-fields/TgfDropDownListFormField";
import TgfCustomFormField from "../../../../core/kendo/form/tgfFormComponent/form-fields/TgfCustomFormField";
import TgfButtonFormField from "../../../../core/kendo/form/tgfFormComponent/form-fields/TgfButtonFormField";
import {Grid} from "@material-ui/core";

export const EditAgencyUserModal = (props) => {
    const {
        closeEditAgencyUserModal,
        incomingAssociate,
        loadAgencyAccessFilters,
        loadHeadOfAgencyCount,
        agencyAccessFilters,
        headOfAgencyCount,
        openDeactivationModal,
    } = props;
    let selectedAssociate = incomingAssociate;

    const ref = useRef();
    const personalDispatch = useDispatch();

    // initial load
    useEffect(() => {
        ref.current?.loadForm();
        ref.current?.setIsEditMode(true);
        loadAgencyAccessFilters();
        loadHeadOfAgencyCount();
    }, []);

    useEffect(() => {
        if (selectedAssociate.isHeadOfAgency) {
            selectedAssociate.accessFilterId = 0;
        }
    }, [agencyAccessFilters]);

    const loadFormData = (loadValue) => {
        const formValues = {
            entity: _.cloneDeep(loadValue.selectedAssociate),
            originalValues: _.cloneDeep(loadValue.selectedAssociate),
            additionalData: {
                headOfAgency: headOfAgencyOptions,
                accessFilter: agencyAccessFilters,
            },
        };

        return formValues;
    };

    const headOfAgencyOptions = [
        {id: true, display: "Yes"},
        {id: false, display: "No"},
    ];

    const handleSubmit = async (data) => {
        if (data) {
            const newAssociateValues = _.cloneDeep(data?.entity);

            newAssociateValues.actionRoleId = newAssociateValues.isHeadOfAgency ? 2 : 3;
            if (newAssociateValues.isHeadOfAgency) newAssociateValues.accessFilterId = 1;

            return await window.shell.gateway.updateAssociateSecurityContext(
                newAssociateValues.id,
                newAssociateValues.actionRoleId,
                newAssociateValues.accessFilterId,
            );
        }
    };

    return (
        <TgfDialog
            title={`Edit Agency User`}
            onClose={closeEditAgencyUserModal}
            width={600}
        >
            <TgfFormComponent
                ref={ref}
                onLoadFormData={loadFormData}
                loadFormArgument={{
                    selectedAssociate: selectedAssociate,
                }}
                validationSchema={agencyUserSchema}
                onSubmit={handleSubmit}
                preRenderComponent={<>Loading...</>}
                processName={'edit-agency-user'}
                {...props}
            >
                <Grid container spacing={2} alignItems={"center"}>
                    <Grid item xs={12}>
                        <TgfCustomFormField
                            displayElementFunction={(tgfFormContext) => (
                                <>
                                    <label className="modal-label">
                                        User:
                                    </label>
                                    {' '}{tgfFormContext.formData.data.entity.firstName}{' '}{tgfFormContext.formData.data.entity.lastName}
                                </>
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TgfDropDownListFormField
                            id="headOfAgency"
                            name="headOfAgency"
                            label="Head of Agency"
                            bindingValue="entity.isHeadOfAgency"
                            validationBindingValue="entity.isHeadOfAgency"
                            dataBindingValue="additionalData.headOfAgency"
                            disabledFunction={(tgfFormContext) => {
                                // Not demote-able if they are the only Head of Agency or a Queen.
                                return tgfFormContext.formData.data.entity.isHeadOfAgency && (headOfAgencyCount === 1 || tgfFormContext.formData.data.entity.roleName === 'Queen');
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TgfDropDownListFormField
                            id="accessFilter"
                            name="accessFilter"
                            label="Visibility Permission"
                            bindingValue="entity.accessFilterId"
                            validationBindingValue="entity.accessFilterId"
                            dataBindingValue="additionalData.accessFilter"
                            disabledFunction={(tgfFormContext) => tgfFormContext.formData.data.entity.isHeadOfAgency}
                            isVisibleFunction={(tgfFormContext) => !tgfFormContext.formData.data.entity.isHeadOfAgency}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TgfButtonFormField
                            variant="text"
                            color="default"
                            type="submit"
                            contentFunction={(tgfFormContext) => "DEACTIVATE CRM ACCESS FOR THIS USER"}
                            onClickHandler={(tgfFormContext) => {
                                selectedAssociate = tgfFormContext.formData.data.originalValues;
                                openDeactivationModal();
                            }}
                            disabledFunction={(tgfFormContext) => tgfFormContext.formData.data.entity.isHeadOfAgency}
                            isVisibleFunction={(tgfFormContext) => !tgfFormContext.formData.data.entity.isHeadOfAgency}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TgfButtonFormField
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabledFunction={(tgfFormContext) => {
                                const disabled = tgfFormContext.formValidation.validationErrors !== null ||
                                    (tgfFormContext.formData.data.originalValues.isHeadOfAgency === tgfFormContext.formData.data.entity.isHeadOfAgency &&
                                        tgfFormContext.formData.data.originalValues.accessFilterId === tgfFormContext.formData.data.entity.accessFilterId);
                                return disabled;
                            }}
                            contentFunction={(tgfFormContext) => "Save"}
                            onClickHandler={async (tgfFormContext) => {
                                try {
                                    await tgfFormContext.formMethods.submitForm();
                                    await personalDispatch(
                                        await window.shell.actions.sys.sendSnackbarMessage({content: 'Agency user changes saved.'})
                                    );
                                } catch {
                                    personalDispatch(
                                        await window.shell.actions.sys.sendSnackbarMessage({content: 'Error. Please contact IT.'})
                                    );
                                } finally {
                                    closeEditAgencyUserModal();
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TgfButtonFormField
                            variant="contained"
                            color=""
                            type="submit"
                            contentFunction={(tgfFormContext) => "Cancel"}
                            onClickHandler={(tgfFormContext) => {
                                selectedAssociate = tgfFormContext.formData.data.originalValues;
                                closeEditAgencyUserModal();
                                tgfFormContext.formMethods.loadForm();
                            }}
                        />
                    </Grid>
                </Grid>
            </TgfFormComponent>
        </TgfDialog>
    )
        ;
};
