import {TgfFormContext} from "../TgfFormContext";

const TgfCustomFormField = ({
                                tgfFormContext = new TgfFormContext({}),
                                isVisibleFunction = (tgfFormContext) => true,
                                displayElementFunction,
                            }) => {
    // Check if the field should be visible
    if (isVisibleFunction(tgfFormContext)) {
        // Render the custom display element if visible
        return displayElementFunction(tgfFormContext);
    }

    // Return nothing (empty JSX) if not visible
    return null;
};

export default TgfCustomFormField;
