import {Tooltip} from "@material-ui/core";
import {useEffect, useState} from "react";
import CircularProgress from '@material-ui/core/CircularProgress';

const LoadOnHoverTooltip = (props) => {
    const {
        width = "fit-content",
        loadTitle
    } = props;
    const [title, setTitle] = useState(null);
    const [isHovered, setIsHovered] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchTitle = async () => {
            if (isHovered === true) {
                setIsLoading(true);
                const title = await loadTitle();
                setTitle(title);
                setIsLoading(false);
            }
        };

        fetchTitle();
    }, [isHovered, loadTitle]);

    return (
        <div style={{ width: width }}>
            <Tooltip title={isLoading ? <CircularProgress size={36} style={{color: "white"}} /> : title}>
                {props.children ? (
                    <div onMouseEnter={() => setIsHovered(true)}>
                        {props.children}
                    </div>
                ) : (
                    <div onMouseEnter={() => setIsHovered(true)}>
                        {props.content}
                    </div>
                )}
            </Tooltip>
        </div>
    );
};

export default LoadOnHoverTooltip;
