import {Grid} from "@material-ui/core";
import {Checkbox} from "@progress/kendo-react-inputs";
import AppMuiCard from "core/components/cards/AppCard";
import TgfPasswordInput from "core/kendo/form/inputs/TgfPasswordInput";
import TgfPhoneNumberInput from "core/kendo/form/inputs/TgfPhoneNumberInput";
import TgfTextBox from "core/kendo/form/inputs/TgfTextBox";
import TgfToggleButton from "core/kendo/form/inputs/TgfToggle";
import TgfAssociateRoleDropdownList from "core/kendo/form/inputs/specialty/TgfAssociateRoleDropdownList";
import FieldError from "crm/components/inputs/FieldError";
import React, {useEffect, useState} from "react";
import ConfirmationModal from "../../../../../../core/kendo/dialog/ConfimationModal";
import TgfAgencyComboBox from "../../../../../../core/kendo/form/inputs/specialty/TgfAgencyComboBox";

const jackRoleOptions = [
    {
        id: 1,
        display: "Unrestricted",
    },
    {
        id: 2,
        display: "Restricted",
    },
    {
        id: 3,
        display: "Filtered",
    },
];

const DetailsCard = (props) => {

    const {
        setFormValues,
        isDirty,
        formValues,
        setIsDirty,
        fieldErrors,
        changeSubmitted,
    } = props;

    const [franchiseModeModalConfirmation, setFranchiseModeModalConfirmation] = useState(false);
    const [showFranchiseModeOption, setShowFranchiseModeOption] = useState(false);
    const [showFranchiseModeEnabled, setShowFranchiseModeEnabled] = useState(false);

    useEffect(() => {
        if (formValues.roles[0]?.name === "Queen") {
            if (formValues.franchiseMode) {
                setShowFranchiseModeEnabled(true);
            } else {
                setShowFranchiseModeOption(true);
            }
        }
    }, [formValues.roles[0]]);

    useEffect(() => {
        if (changeSubmitted && formValues.franchiseMode) {
            setShowFranchiseModeEnabled(true);
        }
    }, [changeSubmitted, formValues.franchiseMode]);

    const handleTextBoxChange = (e) => {
        setFormValues({
            ...formValues,
            [e.target.element.name]: e.target.value,
        });
        setIsDirty(true);
    };

    const handleAssociateRoleChange = (e) => {
        const role = e.target?.value?.name;

        setFormValues({
            ...formValues,
            parentAssociateId: role !== "Jack" ? null : formValues.parentAssociateId,
            hasCarrierPrivilege: role !== "Ace" ? false : formValues.hasCarrierPrivilege,
            collectionRole: role !== "Ace" ? false : formValues.hasCarrierPrivilege,
            roles: [e.target.value],
        });

        if (role === "Queen") {
            setShowFranchiseModeOption(true);
        }
        setIsDirty(true);
    };

    const handleParentAssociateChange = (e) => {
        setFormValues({
            ...formValues,
            parentAssociateId: e.target.value ?? null,
        });
        setIsDirty(true);
    };

    const handleCheckboxChange = (e) => {
        setFormValues({
            ...formValues,
            [e.target.name]: e.target.value,
        });
        setIsDirty(true);
    };

    const handleFranchiseCheckboxChange = (checked) => {
        if (checked) {
            setFranchiseModeModalConfirmation(true);
        } else {
            setFranchiseModeModalConfirmation(false);
            setIsDirty(false);
            setFormValues({
                ...formValues,
                franchiseMode: false,
            });
        }
    };

    const handleEnablingFranchiseMode = () => {
        setFormValues({
            ...formValues,
            franchiseMode: true,
        });
        setIsDirty(true);
        setFranchiseModeModalConfirmation(false);
    };

    const handleFranchiseModeModalConfirmationClose = () => {
        setFranchiseModeModalConfirmation(false);
        setFormValues({
            ...formValues,
            franchiseMode: false,
        });
    };

    const handleJackRoleChange = (e) => {
        console.log(e);
    };

    const handleHeadOfAgencyChange = (e) => {
        console.log(e);
    };

    return (
        <AppMuiCard title={"Details"}>
            {franchiseModeModalConfirmation &&
                <ConfirmationModal
                    title={"Enable Franchise Mode"}
                    description={"Are you sure you want to enable Franchise Mode?"}
                    onClose={handleFranchiseModeModalConfirmationClose}
                    action={handleEnablingFranchiseMode}
                />
            }
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TgfTextBox
                        label={"First Name"}
                        id={"firstName"}
                        name={"firstName"}
                        value={formValues.firstName}
                        onChange={handleTextBoxChange}
                        maxLength={50}
                    />
                    <FieldError>{fieldErrors.firstName}</FieldError>
                </Grid>
                <Grid item xs={6}>
                    <TgfTextBox
                        label={"Last Name"}
                        id={"lastName"}
                        name={"lastName"}
                        value={formValues.lastName}
                        onChange={handleTextBoxChange}
                        maxLength={50}
                    />
                    <FieldError>{fieldErrors.lastName}</FieldError>
                </Grid>
                <Grid item xs={8}>
                    <TgfTextBox
                        label={"Email Address"}
                        id={"emailAddress"}
                        name={"emailAddress"}
                        value={formValues.emailAddress}
                        onChange={handleTextBoxChange}
                        maxLength={50}
                    />
                    <FieldError>{fieldErrors.emailAddress}</FieldError>
                </Grid>
                <Grid item xs={4}>
                    <TgfTextBox
                        label={"CRM ID"}
                        id={"systemId"}
                        name={"systemId"}
                        value={formValues.systemId}
                        onChange={handleTextBoxChange}
                        maxLength={10}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TgfPasswordInput
                        label={"Password"}
                        id={"password"}
                        name={"password"}
                        value={formValues.password}
                        onChange={handleTextBoxChange}
                        maxLength={50}
                    />
                    <FieldError>{fieldErrors.password}</FieldError>
                </Grid>
                {formValues.franchiseMode &&
                    <Grid item xs={8}>
                        <TgfTextBox
                            label={"LLC Name"}
                            id={"agencyName"}
                            name={"agencyName"}
                            value={formValues.agencyName}
                            onChange={handleTextBoxChange}
                            maxLength={50}
                        />
                    </Grid>}
                {formValues.franchiseMode &&
                    <Grid item xs={4}>
                        <TgfTextBox
                            label={"LLC CRM ID"}
                            id={"agencyAbbreviation"}
                            name={"agencyAbbreviation"}
                            value={formValues.agencyAbbreviation}
                            onChange={handleTextBoxChange}
                            maxLength={10}
                        />
                    </Grid>}
                <Grid item xs={10}>
                    <TgfPhoneNumberInput
                        label={"Business Phone"}
                        id={"businessPhone"}
                        name={"businessPhone"}
                        value={formValues.businessPhone}
                        onChange={handleTextBoxChange}
                    />
                    <FieldError>{fieldErrors.businessPhone}</FieldError>
                </Grid>
                <Grid item xs={2}>
                    <TgfTextBox
                        label={"EXT"}
                        id={"businessPhoneExt"}
                        name={"businessPhoneExt"}
                        value={formValues.businessPhoneExt}
                        onChange={handleTextBoxChange}
                        maxLength={10}
                    />
                </Grid>
                <Grid item xs={10}>
                    <TgfPhoneNumberInput
                        label={"Business Fax"}
                        id={"faxPhone"}
                        name={"faxPhone"}
                        value={formValues.faxPhone}
                        onChange={handleTextBoxChange}
                    />
                </Grid>
                <Grid item xs={10}>
                    <TgfTextBox
                        label={"LLC Name"}
                        id={"llcName"}
                        name={"llcName"}
                        value={formValues.llcName}
                        onChange={handleTextBoxChange}
                        maxLength={50}
                        disabled={(formValues.roles ? (formValues.roles[0]?.name !== "Queen") : true)}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TgfAssociateRoleDropdownList
                        label={"Role"}
                        id={"roles"}
                        name={"roles"}
                        value={formValues?.roles ? formValues.roles[0] : null}
                        onChange={handleAssociateRoleChange}
                    />
                    <FieldError>{fieldErrors.roles}</FieldError>
                </Grid>
                <Grid item xs={8}>
                    <TgfAgencyComboBox
                        label={"Queen"}
                        placeholder={"Search..."}
                        onChange={handleParentAssociateChange}
                        value={formValues.parentAssociateId}
                        franchiseMode={null}
                        isDisabled={false}
                        disabled={(formValues.roles ? (formValues.roles[0]?.name !== "Jack") : true)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TgfToggleButton
                        label={"Account Active"}
                        id={"isActive"}
                        name={"isActive"}
                        checked={formValues.isActive}
                        onChange={handleCheckboxChange}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TgfToggleButton
                        label={"Display Payouts"}
                        id={"displayPayouts"}
                        name={"displayPayouts"}
                        checked={formValues.displayPayouts}
                        onChange={handleCheckboxChange}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TgfToggleButton
                        label={"Open Links In New Tab"}
                        id={"openLinksInNewTab"}
                        name={"openLinksInNewTab"}
                        checked={formValues.openLinksInNewTab}
                        onChange={handleCheckboxChange}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TgfToggleButton
                        label={"Carrier Privilege"}
                        id={"hasCarrierPrivilege"}
                        name={"hasCarrierPrivilege"}
                        checked={formValues.hasCarrierPrivilege}
                        onChange={handleCheckboxChange}
                        disabled={(formValues.roles ? (formValues.roles[0]?.name !== "Ace") : true)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TgfToggleButton
                        label={"Collections Role"}
                        id={"collectionRole"}
                        name={"collectionRole"}
                        checked={formValues.collectionRole}
                        onChange={handleCheckboxChange}
                        disabled={(formValues.roles ? (formValues.roles[0]?.name !== "Ace") : true)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TgfToggleButton
                        label={"View Margin Contribution"}
                        id={"canViewMarginContribution"}
                        name={"canViewMarginContribution"}
                        checked={formValues.canViewMarginContribution}
                        onChange={handleCheckboxChange}
                        disabled={(formValues.actionRoleId === 3 && (formValues.accessFilterId === 2 || formValues.accessFilterId === 3))}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TgfToggleButton
                        label={"Locked Date Control"}
                        id={"canEditBlockedDates"}
                        name={"canEditBlockedDates"}
                        checked={formValues.canEditBlockedDates}
                        onChange={handleCheckboxChange}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TgfToggleButton
                        label={"Edit AR Automation Block"}
                        id={"canEditBlockedDatesArBilling"}
                        name={"canEditBlockedDatesArBilling"}
                        checked={formValues.canEditBlockedDatesArBilling}
                        onChange={handleCheckboxChange}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TgfToggleButton
                        label={"View Accounting Tab"}
                        id={"canViewAccountingTab"}
                        name={"canViewAccountingTab"}
                        checked={formValues.canViewAccountingTab}
                        onChange={handleCheckboxChange}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TgfToggleButton
                        label={"Edit Customer Billing Tab"}
                        id={"canEditCustomerBilling"}
                        name={"canEditCustomerBilling"}
                        checked={formValues.canEditCustomerBilling}
                        onChange={handleCheckboxChange}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TgfToggleButton
                        label={"Collectors Assignment"}
                        id={"canViewCollectionsAssignment"}
                        name={"canViewCollectionsAssignment"}
                        checked={formValues.canViewCollectionsAssignment}
                        onChange={handleCheckboxChange}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TgfToggleButton
                        label={"View Back Office Help Docs"}
                        id={"canViewBackOfficeHelpDocs"}
                        name={"canViewBackOfficeHelpDocs"}
                        checked={formValues.canViewBackOfficeHelpDocs}
                        onChange={handleCheckboxChange}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TgfToggleButton
                        label={"View Agency Page"}
                        id={"canViewAgencyPage"}
                        name={"canViewAgencyPage"}
                        checked={formValues.canViewAgencyPage}
                        onChange={handleCheckboxChange}
                        disabled={(formValues.roles ? (formValues.roles[0]?.name !== "Ace") : true)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TgfToggleButton
                        label={"Edit Business Days"}
                        id={"canEditBusinessDays"}
                        name={"canEditBusinessDays"}
                        checked={formValues.canEditBusinessDays}
                        onChange={handleCheckboxChange}
                    />
                </Grid>
                {showFranchiseModeOption &&
                    <>
                        <Grid item xs={12} style={{textAlign: "center"}}>
                            <h2>Franchise Mode</h2>
                            <hr/>
                        </Grid>
                        <Grid item sm={12}>
                            <Checkbox
                                value={formValues.franchiseMode ?? false}
                                id={"franchiseMode"}
                                name={"franchiseMode"}
                                label={'Franchise Mode Enabled'}
                                onChange={(e) => handleFranchiseCheckboxChange(e.target.value)}
                            />
                        </Grid>
                    </>
                }
                {showFranchiseModeEnabled &&
                    <>
                        <Grid item xs={12} style={{textAlign: "center"}}>
                            <h2>Franchise Mode</h2>
                            <hr/>
                        </Grid>
                        <Grid item sm={12}>
                            <p>Franchise Mode Enabled</p>
                            <a href={"/agency"}>Go to the Agency tab to change Franchise Mode Settings</a>
                        </Grid>
                    </>
                }
                <Grid item xs={12} style={{textAlign: "center"}}>
                    <h2>Rate Confirmation Information</h2>
                    <hr/>
                </Grid>
                <Grid item xs={10}>
                    <TgfPhoneNumberInput
                        label={"Business Phone"}
                        id={"rateConBusinessPhone"}
                        name={"rateConBusinessPhone"}
                        value={formValues.rateConBusinessPhone}
                        onChange={handleTextBoxChange}
                    />
                </Grid>
                <Grid item xs={2}>
                    <TgfTextBox
                        label={"EXT"}
                        id={"rateConBusinessPhoneExt"}
                        name={"rateConBusinessPhoneExt"}
                        value={formValues.rateConBusinessPhoneExt}
                        onChange={handleTextBoxChange}
                        maxLength={10}
                    />
                </Grid>
                <Grid item xs={10}>
                    <TgfPhoneNumberInput
                        label={"Business Fax"}
                        id={"rateConFaxPhone"}
                        name={"rateConFaxPhone"}
                        value={formValues.rateConFaxPhone}
                        onChange={handleTextBoxChange}
                    />
                </Grid>
                <Grid item xs={8}>
                    <TgfTextBox
                        label={"Email Address"}
                        id={"rateConEmailAddress"}
                        name={"rateConEmailAddress"}
                        value={formValues.rateConEmailAddress}
                        onChange={handleTextBoxChange}
                        maxLength={50}
                    />
                </Grid>
            </Grid>
        </AppMuiCard>
    );
};

export default DetailsCard;
