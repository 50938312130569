import React, {useEffect, useState} from 'react';
import FullWidthLayout from '../../../core/layouts/FullWidthLayout';
import {Grid} from '@material-ui/core';
import {ArrowBack, FileCopy} from '@material-ui/icons';
import AppMuiButton from '../../../core/components/AppButton';
import {Link} from 'react-router-dom';
import "./DuplicationPage.scss";
import CurrentCreditRemaining from "../DetailsPage/includes/CurrentCreditRemaining";
import {useDispatch, useSelector} from "react-redux";
import AdditionalDuplicationOptions from "./include/AdditionalDuplicationOptions";
import RuleChecks from "./include/RuleChecks";
import Companies from "./include/Companies";
import Header from "./include/Header";
import HasDuplicatedShipment from "./include/HasDuplicatedShipment";
import {usePageTitle} from "../../../crm/components/customHooks/misc/usePageTitle";

const isActive = (company) =>
    company?.isInactive === false;

const isNotDisabled = (company) =>
    company?.isDisabled === false;

const hasPrimaryContact = (contacts) =>
    contacts?.length > 0 &&
    contacts.some(c => c.isPrimary);

const DuplicationPage = (props) => {

    const bolNumber = parseInt(props.match.params.id);
    usePageTitle(`Ship ${bolNumber}`);

    const personalDispatch = useDispatch();

    const associate = useSelector((state) => {
        return state.persona.associate;
    });

    const [isRenderable, setIsRenderable] = React.useState(false);
    const [customerCreditStatus, setCustomerCreditStatus] = useState(null);
    const [duplicateCount, setDuplicateCount] = React.useState(null);
    const [duplicationConstraints, setDuplicationConstraints] = useState([]);
    const [coreCompanyOptions, setCoreCompanyOptions] = useState(null);
    const [multiStopCompanyOptions, setMultiStopCompanyOptions] = useState(null);
    const [canDuplicate, setCanDuplicate] = useState(false);
    const [duplicateBolNumbers, setDuplicateBolNumbers] = useState([]);
    const [mappingConfig, setMappingConfig] = useState({});
    const [isCarrierGoodToGo, setIsCarrierGoodToGo] = useState(false);
    const [shipment, setShipment] = useState(null);


    const currencyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });

    const getReferenceValue = (label, value) => {
        if (label === null && value === null) {
            return null;
        }

        return `${label || ''} ${value || ''}`;
    };

    const createMappingConfig = (shipment, invoice, carrierContact) => {

        return {
            timeAndCost: [
                {
                    label: "Pickup Time",
                    mapValue: false,
                    valueToMap: shipment.pickupTime,
                    originalValue: shipment.pickupTime,
                    isMultiValue: false,
                    order: 0
                },
                {
                    label: "Carrier Cost",
                    mapValue: false,
                    valueToMap: currencyFormatter.format(invoice.carrierCost),
                    originalValue: invoice.carrierCost,
                    isMultiValue: false,
                    order: 1
                },
                {
                    label: "Delivery Time",
                    mapValue: false,
                    valueToMap: shipment.deliveryTime,
                    originalValue: shipment.deliveryTime,
                    isMultiValue: false,
                    order: 2
                },
                {
                    label: "Customer Cost",
                    mapValue: false,
                    valueToMap: currencyFormatter.format(invoice.customerCost),
                    originalValue: invoice.customerCost,
                    isMultiValue: false,
                    order: 3
                }
            ],
            CarrierContactDetail: [
                {
                    label: "Carrier MC / Name",
                    mapValue: false,
                    valueToMap: shipment.carrierMcNumber + ' / ' + shipment.carrier.name,
                    originalValue: shipment.carrierMcNumber,
                    isMultiValue: false,
                    order: 0
                },
                {
                    label: "Carrier Rep",
                    mapValue: false,
                    valueToMap: carrierContact?.repName || null,
                    originalValue: carrierContact?.repName,
                    isMultiValue: false,
                    order: 0
                },
                {
                    label: "Rep. Phone",
                    mapValue: false,
                    valueToMap: carrierContact?.repPhone || null,
                    originalValue: carrierContact?.repPhone,
                    isMultiValue: false,
                    order: 0
                },
                {
                    label: "Rep. Email",
                    mapValue: false,
                    valueToMap: carrierContact?.repEmail || null,
                    originalValue: carrierContact?.repEmail,
                    isMultiValue: false,
                    order: 0
                }
            ],
            DriverDetail: [
                {
                    label: "Driver Name",
                    mapValue: false,
                    valueToMap: shipment.driverName,
                    originalValue: shipment.driverName,
                    isMultiValue: false,
                    order: 0
                },
                {
                    label: "Driver Phone",
                    mapValue: false,
                    valueToMap: shipment.driverPhone,
                    originalValue: shipment.driverPhone,
                    isMultiValue: false,
                    order: 1
                },
                {
                    label: "Tractor Number",
                    mapValue: false,
                    valueToMap: shipment.truckNumber,
                    originalValue: shipment.truckNumber,
                    isMultiValue: false,
                    order: 2
                },
                {
                    label: "Trailer Number",
                    mapValue: false,
                    valueToMap: shipment.trailerNumber,
                    originalValue: shipment.trailerNumber,
                    isMultiValue: false,
                    order: 3
                }
            ],
            ReferenceNumbers: [
                {
                    label: "Pickup #",
                    mapValue: false,
                    valueToMap: shipment.pickupNumber,
                    originalValue: shipment.pickupNumber,
                    isMultiValue: false,
                    order: 0
                },
                {
                    label: "Delivery #",
                    mapValue: false,
                    valueToMap: shipment.deliveryNumber,
                    originalValue: shipment.deliveryNumber,
                    isMultiValue: false,
                    order: 1
                },
                {
                    label: "Ref Field #1",
                    mapValue: false,
                    valueToMap: getReferenceValue(shipment.refNum1Description, shipment.refNum1),
                    originalValue: shipment.refNum1Description,
                    originalValue2: shipment.refNum1,
                    isMultiValue: true,
                    order: 2
                },
                {
                    label: "Ref Field #2",
                    mapValue: false,
                    valueToMap: getReferenceValue(shipment.refNum2Description, shipment.refNum2),
                    originalValue: shipment.refNum2Description,
                    originalValue2: shipment.refNum2,
                    isMultiValue: true,
                    order: 3
                },
                {
                    label: "Ref Field #3",
                    mapValue: false,
                    valueToMap: getReferenceValue(shipment.refNum3Description, shipment.refNum3),
                    originalValue: shipment.refNum3Description,
                    originalValue2: shipment.refNum3,
                    isMultiValue: true,
                    order: 4
                },
                {
                    label: "Ref Field #4",
                    mapValue: false,
                    valueToMap: getReferenceValue(shipment.refNum4Description, shipment.refNum4),
                    originalValue: shipment.refNum4Description,
                    originalValue2: shipment.refNum4,
                    isMultiValue: true,
                    order: 5
                }
            ]
        };
    };

    const loadPageDependencies = async () => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('loadPageDependencies'));

            const shipment = await window.shell.gateway.getShipment(bolNumber);
            setShipment(shipment);
            setIsCarrierGoodToGo(shipment.carrier.isGoodToGo);
            const carrierContact = await window.shell.gateway.getCarrierContact(bolNumber);

            // Gather company and contacts data for the customer/shipper/consignee.
            const companies = [
                shipment.customerId,
                shipment.shipperId,
                shipment.consigneeId
            ];

            const allPromises = companies
                .map(companyId => companyId && window.shell.gateway.getCompany(companyId))
                .concat(companies
                    .map(companyId => companyId && window.shell.gateway.getCompanyContacts(companyId)))
                .concat([window.shell.gateway.getCompanyCreditStatus(shipment.customerId)])
                .concat([window.shell.gateway.getInvoice(bolNumber)]);

            const [
                customer,
                shipper,
                consignee,
                customerContacts,
                shipperContacts,
                consigneeContacts,
                companyCreditRemaining,
                invoice
            ] = await Promise.all(allPromises);

            setCustomerCreditStatus(companyCreditRemaining);

            // If the shipment is multi-stop, gather the companies and their contacts.
            if (shipment.isMultipleStop) {

                const shipmentMultipleStops = await window.shell.gateway.getShipmentMultipleStop(bolNumber);

                const multiStopCompanies = shipmentMultipleStops
                    .map(ms => ms.company);

                const multiStopContactPromises = multiStopCompanies
                    .map(c => window.shell.gateway.getCompanyContacts(c.id));

                const multiStopCompanyContacts = await Promise.all(multiStopContactPromises);

                setMultiStopCompanyOptions(multiStopCompanies
                    .map((company, index) => ({
                        type: `Stop #${index + 1}`,
                        name: company.name,
                        id: company.id
                    })));

                if (multiStopCompanies.length > 0) {
                    multiStopCompanies.forEach((company, index) => {
                        duplicationConstraints.push({
                            title: `Stop #${index + 1} company is active.`,
                            passed: isActive(company)
                        });

                        const companyContacts = multiStopCompanyContacts[index] || [];

                        duplicationConstraints.push({
                            title: `Stop #${index + 1} company has a primary contact.`,
                            passed: companyContacts.some(c => c.isPrimary)
                        });
                    });
                }

            }

            if (shipment) {
                const innerDuplicationConstraints = [
                    {
                        title: 'Customer has credit. ($2,000+)',
                        passed: companyCreditRemaining.companyCreditRemaining >= 2000
                    },
                    {
                        title: 'Customer is active.',
                        passed: isActive(customer)
                    },
                    {
                        title: 'Customer is not disabled.',
                        passed: isNotDisabled(customer)
                    },
                    {
                        title: 'Customer has a primary contact.',
                        passed: hasPrimaryContact(customerContacts)
                    },
                    {
                        title: 'Shipper is active.',
                        passed: isActive(shipper)
                    },
                    {
                        title: 'Shipper has a primary contact.',
                        passed: hasPrimaryContact(shipperContacts)
                    },
                    {
                        title: 'Consignee is active.',
                        passed: isActive(consignee)
                    },
                    {
                        title: 'Consignee has a primary contact.',
                        passed: hasPrimaryContact(consigneeContacts)
                    }
                ];

                const innerCoreCompanyOptions = [
                    {
                        type: 'Customer',
                        name: customer?.name,
                        id: customer?.id
                    },
                    {
                        type: 'Shipper',
                        name: shipper?.name,
                        id: shipper?.id
                    },
                    {
                        type: 'Consignee',
                        name: consignee?.name,
                        id: consignee?.id
                    }
                ];

                setDuplicationConstraints(innerDuplicationConstraints);
                setCoreCompanyOptions(innerCoreCompanyOptions);
            }

            setMappingConfig(createMappingConfig(shipment, invoice, carrierContact));
            setIsRenderable(true);
        } catch (e) {
            setIsRenderable(false);
            console.error(e.message);
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('loadPageDependencies'));
        }
    };

    useEffect(() => {
        loadPageDependencies();
    }, [bolNumber]);

    useEffect(() => {
        if (duplicationConstraints) {
            setCanDuplicate(duplicationConstraints.every(c => c.passed) &&
                duplicateCount > 0 &&
                duplicateCount < 21);
        }
    }, [duplicateCount]);


    const handleDuplicateClick = async () => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('duplicating shipment'));
            const innerDuplicateBolNumbers = await window.shell.gateway.duplicateShipment(bolNumber, duplicateCount, mappingConfig);

            const noteCreateDate = new Date();

            //Add notes
            for (let bolNum of innerDuplicateBolNumbers) {
                const trackingNote = window.shell.gateway.createShipmentTrackingNote();
                trackingNote.bolNumber = bolNum;
                trackingNote.associateId = associate.id;
                trackingNote.createdDate = noteCreateDate;
                trackingNote.trackingDate = noteCreateDate;
                trackingNote.note = `Shipment Created By: ${associate.fullName}`;
                trackingNote.loadTrackingPhaseId = 8;
                await window.shell.gateway.saveLoadTrackingNote(trackingNote);
            }

            setDuplicateBolNumbers(innerDuplicateBolNumbers);
            setDuplicateCount(null);

            await loadPageDependencies();

            personalDispatch(await window.shell.actions.sys.sendSnackbarMessage({content: `Shipment ${bolNumber} duplicated ${duplicateCount} time(s).`}));
        } catch (e) {
            console.error(e.message);
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('duplicating shipment'));
        }
    };

    const title = `Duplicate Shipment - ${bolNumber}`;

    const hasDuplicatedShipment = duplicateBolNumbers.length > 0;

    return (
        <FullWidthLayout title={title}>
            {isRenderable &&
                <Grid container spacing={2}>
                    <Grid container item spacing={2} xs={12}>
                        <Grid item>
                            <AppMuiButton
                                type={'button'}
                                startIcon={<FileCopy/>}
                                disabled={!canDuplicate}
                                onClick={handleDuplicateClick}>
                                Duplicate
                            </AppMuiButton>
                        </Grid>
                        <Grid item>
                            <AppMuiButton
                                component={Link}
                                to={`/shipment/${bolNumber}/details`}
                                startIcon={<ArrowBack/>}>
                                Back to Shipment
                            </AppMuiButton>
                        </Grid>

                        <CurrentCreditRemaining creditStatus={customerCreditStatus} bolNumber={bolNumber}/>

                    </Grid>
                    <Grid container item xs={12} spacing={2}>
                        <Header duplicateCount={duplicateCount} setDuplicateCount={setDuplicateCount}/>
                        {
                            hasDuplicatedShipment && <HasDuplicatedShipment duplicateBolNumbers={duplicateBolNumbers}/>
                        }
                        <Grid item xs={12} md={2}>
                            <Companies coreCompanyOptions={coreCompanyOptions}
                                       multiStopCompanyOptions={multiStopCompanyOptions}/>
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <RuleChecks duplicationConstraints={duplicationConstraints}/>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <AdditionalDuplicationOptions
                                setMappingConfig={setMappingConfig}
                                mappingConfig={mappingConfig}
                                carrierGoodToGo={isCarrierGoodToGo}
                                carrierComplianceNote={shipment?.carrier?.complianceNote}
                            />
                        </Grid>
                    </Grid>
                </Grid>}
        </FullWidthLayout>
    );
};

export default DuplicationPage;
