import React, {useEffect, useRef} from "react";
import TgfDialog from "../../../../core/kendo/dialog/TgfDialog";
import "./agencyUser.scss";
import TgfFormComponent from "../../../../core/kendo/form/tgfFormComponent/TgfFormComponent";
import _ from "lodash";
import {useDispatch} from "react-redux";
import {Grid, Typography} from "@material-ui/core";
import TgfCustomFormField from "../../../../core/kendo/form/tgfFormComponent/form-fields/TgfCustomFormField";
import TgfButtonFormField from "../../../../core/kendo/form/tgfFormComponent/form-fields/TgfButtonFormField";

export const DeactivateUserModal = (props) => {
    const {
        reloadGrid,
        closeEditAgencyUserModal,
        closeDeactivationOpenEditAgencyUserModal,
        incomingAssociate,
        loadAgencyAccessFilters,
        agencyAccessFilters,
    } = props;

    let selectedAssociate = incomingAssociate;

    const ref = useRef();
    const personalDispatch = useDispatch();

    // initial load
    useEffect(() => {
        ref.current?.loadForm();
    }, []);

    const loadFormData = (loadValue) => {
        const formValues = {
            entity: _.cloneDeep(loadValue.selectedAssociate),
        };

        return formValues;
    };

    const handleSubmit = async (data) => {
        if (data) {
            const associateId = _.cloneDeep(data?.entity?.id);

            await window.shell.gateway.deactivateAgencyOpsAssociate(associateId);

            closeEditAgencyUserModal();

            reloadGrid();
        }
    };

    return (
        <TgfDialog
            title={`Deactivate CRM Access for User`}
            onClose={closeDeactivationOpenEditAgencyUserModal}
            width={800}
        >
            <TgfFormComponent
                ref={ref}
                onLoadFormData={loadFormData}
                loadFormArgument={{
                    selectedAssociate: selectedAssociate,
                }}
                onSubmit={handleSubmit}
                preRenderComponent={<>Loading...</>}
                processName={'edit-agency-user'}
                {...props}
            >
                <Grid container spacing={2} alignItems={"center"}>
                    <Grid item xs={12}>
                        <TgfCustomFormField
                            displayElementFunction={(tgfFormContext) => (
                                <Typography variant="body">
                                    <label className={"modal-label"}>
                                        User:
                                    </label>
                                    {' '}{tgfFormContext.formData.data.entity.firstName}{' '}{tgfFormContext.formData.data.entity.lastName}
                                </Typography>
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TgfCustomFormField
                            displayElementFunction={(tgfFormContext) => (
                                <Typography variant="body">
                                    Click the "Confirm Deactivation" button below to log this user out of the CRM
                                    and{' '}
                                    deactivate their access.
                                </Typography>
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TgfCustomFormField
                            displayElementFunction={(tgfFormContext) => (
                                <Typography variant="h6" style={{color: "#dc3545", fontWeight: "bold"}}>
                                    This deactivation process should only be used to quickly remove the user's CRM
                                    access
                                    when the need arises. Tallgrass personnel MUST be notified if the user is being
                                    permanently terminated. Keep in mind that only Tallgrass personnel can reactivate a
                                    user's CRM access.
                                </Typography>
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TgfCustomFormField
                            displayElementFunction={(tgfFormContext) => (
                                <Typography
                                    variant="h6"
                                    style={{paddingTop: 7, paddingBottom: 10, color: "#007bff", fontWeight: "bold"}}
                                >
                                    If this user is a Restricted user and they are being permanently terminated, this
                                    could affect your visibility to what customers they were the owners of or assigned
                                    to.
                                    Let the back office know immediately in such situations so they can make the
                                    transition/reassigning of accounts an easy process.
                                </Typography>
                            )}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TgfButtonFormField
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabledFunction={(tgfFormContext) => {
                                const disabled = tgfFormContext.formValidation.validationErrors !== null || tgfFormContext.formData.data.entity.isHeadOfAgency === true;
                                return disabled;
                            }}
                            contentFunction={(tgfFormContext) => "CONFIRM DEACTIVATION"}
                            onClickHandler={async (tgfFormContext) => {
                                try {
                                    await tgfFormContext.formMethods.submitForm();
                                    await personalDispatch(
                                        await window.shell.actions.sys.sendSnackbarMessage({
                                            content: 'Agency user changes saved.',
                                        })
                                    );
                                } catch {
                                    personalDispatch(
                                        await window.shell.actions.sys.sendSnackbarMessage({
                                            content: 'Error. Please contact IT.',
                                        })
                                    );
                                } finally {
                                    closeDeactivationOpenEditAgencyUserModal();
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TgfButtonFormField
                            variant="contained"
                            color="default"
                            type="submit"
                            contentFunction={(tgfFormContext) => "Cancel"}
                            onClickHandler={(tgfFormContext) => {
                                closeDeactivationOpenEditAgencyUserModal();
                                tgfFormContext.formMethods.loadForm();
                            }}
                        />
                    </Grid>
                </Grid>
            </TgfFormComponent>
        </TgfDialog>
    );
};
