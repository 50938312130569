import React, {useEffect, useState} from 'react';
import {Box, Grid, Table, TableCell, TableRow} from "@material-ui/core";
import {Info} from "@material-ui/icons";
import CrmAssociateDropDown from "../../../../crm/components/CrmAssociateDropDown";
import AppButton from "../../../../core/components/AppButton";
import TgfDialog from "../../../../core/kendo/dialog/TgfDialog";
import useIsAdministrator from "../../../../hubs/personaV2/selectors/useIsAdministrator";

import './AgentRankDialog.scss';
import AppPercentText from "../../../../core/components/text/AppPercentText";
import AppCurrencyText from "../../../../core/components/text/AppCurrencyText";
import useIsHeadOfAgency from "../../../../hubs/personaV2/selectors/useIsHeadOfAgency";

/* WAR - Weekly Agent Rank */
const AgentRankDialog = (props) => {

    const isAdmin = useIsAdministrator();
    const isHeadOfAgency = useIsHeadOfAgency();
    const { date, defaultAssociate, onClose} = props;

    const [selectedAssociate, setSelectedAssociate] = useState(defaultAssociate);
    const [associateMetrics, setAssociateMetrics] = useState();

    useEffect(() => {
        loadAgentMetrics();
    }, [selectedAssociate]);

    const loadAgentMetrics = async () => {
        if (selectedAssociate) {
            const metrics = await window.shell.gateway.getAssociateMetrics(selectedAssociate.id, date.getFullYear(), date.getMonth() + 1);
            setAssociateMetrics(metrics);
        }
    };

    const handleAssociateChange = (associate) => setSelectedAssociate(associate);

    return (
        <TgfDialog title={"WAR"} open={true} onClose={onClose} width={570} className={"war-table-dialog"}>
            <Grid container>
                {isAdmin && <Grid item className={"war-table-header with-dropdown"} xs={8}>
                    <Box>
                        <Box>Agent:</Box>
                        <CrmAssociateDropDown
                            id={"warAssociate"}
                            defaultAssociate={defaultAssociate}
                            onChangeAssociate={handleAssociateChange} />
                    </Box>
                </Grid>}
                {!isAdmin && isHeadOfAgency && <Grid item className={"war-table-header"}>
                    Agent: <strong>{defaultAssociate.fullName}</strong>
                </Grid>}
            </Grid>
            <Table>
                {associateMetrics && <>
                <TableRow>
                    <TableCell class={"war-table-label war-table-total"}>Monthly GM Total:</TableCell>
                    <TableCell>
                        <AppCurrencyText value={associateMetrics.month.totalMargin}/>
                    </TableCell>
                    <TableCell class={"war-table-label war-table-total"}>YTD GM Total:</TableCell>
                    <TableCell>
                        <AppCurrencyText value={associateMetrics.year.totalMargin}/>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell class={"war-table-label"}>Rank:</TableCell>
                    <TableCell>{associateMetrics.month.ranks.totalMargin} out of {associateMetrics.month.ranks.totalAssociates}</TableCell>
                    <TableCell class={"war-table-label"}>Rank:</TableCell>
                    <TableCell>{associateMetrics.year.ranks.totalMargin} out of {associateMetrics.year.ranks.totalAssociates}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell class={"war-table-label war-table-percent"}>Monthly Avg GM %:</TableCell>
                    <TableCell>
                        <AppPercentText value={associateMetrics.month.averageMarginPercent} />
                    </TableCell>
                    <TableCell class={"war-table-label war-table-percent"}>YTD Avg GM %:</TableCell>
                    <TableCell>
                        <AppPercentText value={associateMetrics.year.averageMarginPercent} />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell class={"war-table-label"}>Rank:</TableCell>
                    <TableCell>{associateMetrics.month.ranks.averageMarginPercent} out of {associateMetrics.month.ranks.totalAssociates}</TableCell>
                    <TableCell class={"war-table-label"}>Rank:</TableCell>
                    <TableCell>{associateMetrics.year.ranks.averageMarginPercent} out of {associateMetrics.year.ranks.totalAssociates}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell colspan={4}>
                        <Box  className={"war-table-note"}>
                            <span><Info /></span>
                            <span>Rankings only include currently active agencies who have/had shipments for the month/year being viewed.</span>
                        </Box>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell colSpan={4} class={"war-table-footer"}>
                        <AppButton onClick={onClose}>Close</AppButton>
                    </TableCell>
                </TableRow>
                </>}
            </Table>
        </TgfDialog>
    )
}

export default AgentRankDialog;
